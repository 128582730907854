import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, EditIcon, Loader, FormCheckbox, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon, CheckmarkCircleIcon, InfoIcon, FluidIcon } from '@fluentui/react-icons-northstar';
import SettingsService from "../../services/settingsservice";
import { CellZohoURL, CellVNetTypes, ActionIcon, rowRender, CellSPAccess } from "../../shared/appcomponents/custom-grid-cells";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { InfraDocsReactFlow } from "../../shared/appcomponents/infra-Docs";
import { RenderTooltipCell } from "../../shared/appcomponents/TooltipWithCopy"

const SettingsPortals = (props) => {
  const [portals, setPortals] = React.useState(null);
  const [platforms, setPlatforms] = React.useState(null);
  const [azureVNet, setAzureVNet] = React.useState(null);
  const [azureVNetTypes, setAzureVNetTypes] = React.useState(null);
  const [dbRepoVersions, setDbRepoVersions] = React.useState(null);
  const [currencies, setCurrencies] = React.useState(null);
  const [billPeriods, setBillPeriods] = React.useState(null);
  const [regions, setRegions] = React.useState(null);
  const [productStreams, setProductStreams] = React.useState(null);
  const [timezones, setTimezones] = React.useState(null);
  const [countries, setCountries] = React.useState(null);
  const [storageAccounts, setStorageAccounts] = React.useState(null);
  const [isLoaded, SetLoaded] = React.useState(false);
  const [instanceData, SetInstanceData] = useState(null);
  const [instanceConnectionData, SetConnectionInstanceData] = useState(null);
  const [visible, setVisible] = React.useState(false);
  const [visibleVNet, setVisibleVNet] = React.useState(false);
  const [visibleInstanceConnection, setVisibleInstanceConnection] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteInstanceObj, setDeleteInstanceObj] = useState(null);
  const [isUpdating, setUpdating] = React.useState(false);
  const [businessHourProfiles, setBusinessHourProfiles] = React.useState(null);

  const [visibleInfraDoc, setVisibleInfraDoc] = React.useState(false);
  const [infraDocInst, setInfraDocInst] = React.useState(null);
  const [expandedRowId, setExpandedRowId] = useState(null); // Expand row state

  const addEditSubmitClickHandler = () => {
    props.acquireToken(authSuccessAddEditInstanceCallback);
    setVisible(false);
    SetInstanceData(null);
    setEditMode(false);
    setIsValid(false);
  };

  const instanceConnectionUpdateSubmitClickHandler = () => {
    props.acquireToken((token) => {
      SettingsService.UpdateInstanceConnection(token, instanceConnectionData).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          portals.find(f => f.InstanceId == instanceConnectionData.instanceid).isDBConnectionConfigured = true;

        }
        else if (response && response.status == 401) {
          debugger;
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
      setVisibleInstanceConnection(false);
      SetConnectionInstanceData(null);
    });
  };

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "InstanceName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [
        { field: "InstanceName", operator: "contains", value: value },
        { field: "InstanceURL", operator: "contains", value: value },
        { field: "DBName", operator: "contains", value: value },
        { field: "DBRepoVersion", operator: "contains", value: value },
        { field: "WebApplicationVersion", operator: "contains", value: value },
        { field: "ZohoURL", operator: "contains", value: value },
        { field: "PlatformName", operator: "contains", value: value },
        { field: "ProductStreamName", operator: "contains", value: value },
        { field: "Timezone", operator: "contains", value: value },
        { field: "CountryName", operator: "contains", value: value },
        { field: "Region", operator: "contains", value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const CellVNetTypeCol = (prop) => {
    return <CellVNetTypes   {...prop} user={props.user} vnetTypes={azureVNetTypes}
      onChange={() => {
        props.acquireToken((token) => {
          console.warn(prop.dataItem);
          setUpdating(true);
          UpdateVNetType(token, prop.dataItem);
        }
        )
      }
      }
    />
  }


  const UpdateVNetType = (token, dataItem) => {


    let _params = {
      VNetId: dataItem.VNetId,
      VNetTypeId: dataItem.VNetTypeId,

    }



    SettingsService.UpdateVNetType(token, _params).then((response) => {
      if (response && response.status == 200) {
        response = response.data;
        debugger;
        setUpdating(false);
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
      }
      else if (response && response.status == 401) {
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
      }
      else {
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
      }
      dataItem.IsUpdating = false;

    });

  }

  useEffect(() => checkIsValid(), [instanceData]);

  const expandChange = (event) => {
    let newData = portals.map((item) => {
      if (item.InstanceId === event.dataItem.InstanceId) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setPortals(newData);
  };

  const authSuccessAddEditInstanceCallback = (token) => {
    if (token) {
      if (editMode) {
        debugger;
        SettingsService.UpdateInstance(token, instanceData).then((response) => {
          debugger;
          if (response && response.status == 200) {
            response = response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } else if (response && response.status == 401) {
            debugger;
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      } else {
        SettingsService.AddInstance(instanceData, token).then((response) => {
          if (response && response.status == 200) {
            response = response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } else if (response && response.status == 401) {
            debugger;
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    }
  };

  const authSuccessUpdateTogglesCallback = (token, instanceData) => {
    if (token) {
      SettingsService.UpdateInstance(token, instanceData).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        } else if (response && response.status == 401) {
          debugger;
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }
      });
    }
  };

  const authSuccessRemoveInstanceCallback = (token, instanceId) => {
    if (token) {
      let _params = {
        instanceId: instanceId,
      };
      SettingsService.RemoveInstance(token, _params).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          props.acquireToken(authSuccessCallback);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        }
        else if (response && response.status == 401) {
          debugger;
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

        }
        else {
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
        }

      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const handleCurrencyChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, CurrencyName: data.value.header });
  };

  const handlePlatformChange = (alwaysNull, data) => {
    // debugger;
    SetInstanceData({ ...instanceData, PlatformName: data.value.header });
  };

  const handleVNetChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, VNetId: data.value.value, VNetName: data.value.header });
  };

  const handleDBRepoChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, DBRepoVersion: data.value.value });
  };


  const handleStorageAccountNameChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, StorageAccountName: data.value.header });
  };

  const handleBusinessHourProfileChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, BusinessHourProfileName: data.value.header, BusinessHourProfileId: data.value.value });
  };

  const handleBillPeriodChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, BillPeriodId: data.value.value, GenerateBillOnDay: data.value.header });
  };

  const handleCountryChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, CountryName: data.value.header });
  };

  const handleRegionChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, Region: data.value.header });
  };

  const handleAlertsDisabledChange = (checked) => {
    SetInstanceData({ ...instanceData, AlertsDisabled: checked });
  };

  const handleIsBusinessHourRuleAppliedChange = (checked) => {
    SetInstanceData({ ...instanceData, IsBusinessHourRuleApplied: checked });
  };

  const handleProductStreamChange = (alwaysNull, data) => {
    SetInstanceData({
      ...instanceData,
      ProductStreamId: data.value.value,
      ProductStreamName: data.value.header,
    });
  };

  const handleTimezoneChange = (alwaysNull, data) => {
    SetInstanceData({ ...instanceData, Timezone: data.value.header });
  };


  const handleInfraDocClickOpen = (dataItem, storageAccounts) => {
    debugger;
    setVisibleInfraDoc(true);
    setInfraDocInst(dataItem);
  };
  const handleInfraDocClickClose = (dataItem, isEdit) => {
    setVisibleInfraDoc(false);
    setInfraDocInst(null);
  };

  React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetPortalData(token).then((response) => {
        if (response && response.status == 200) {
          response = response.data;
          setPortals(response);
          SettingsService.GetMasterInstanceServerData(token).then(
            (response) => {
              if (response && response.status == 200) {
                response = response.data;
                setPlatforms(response.Platform);
                setAzureVNet(response.AzureVNets);
                setAzureVNetTypes(response.VNetTypes);

                setDbRepoVersions(response?.DbRepoVersions?.map((m) => ({ header: m.DbRepoVersion, value: m.DbRepoVersion, content: m.DbRepoVersionDesc })));
                setRegions(response.Region);
                setProductStreams(response.ProductStream);
                setTimezones(response.Timezone);
                setCountries(response.Country);
                setStorageAccounts(response.StorageAccount);
                setCurrencies(response.Currencies);
                setBillPeriods(response.BillPeriods);
                setBusinessHourProfiles(response.BusinessHourProfiles)
              }
            }
          );
        }
        else if (response && response.status == 401) {
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }
        SetLoaded(true);
      });
    }
  };

  const ActionIconPortals = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<EditIcon />}
          text
          onClick={() => handleAddEditClickOpen(props.dataItem, true)}
        />
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteInstanceObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }
          }
        />
      </td>
    );
  };

  const removeData = () => {
    if (deleteInstanceObj != null) {
      props.acquireToken((token) =>
        authSuccessRemoveInstanceCallback(token, deleteInstanceObj.InstanceId)
      );
    }
    setDeleteConfirmationDialog(false);
    setDeleteInstanceObj(null);
  };

  const handleAddEditClickOpen = (dataItem, isEdit) => {
    setVisible(true);
    if (isEdit === true) {
      SetInstanceData(dataItem);
      setEditMode(true);
      checkIsValid();
    }
    else {
      SetInstanceData(null);
    }
  };

  const handleVNetsClickOpen = (dataItem, isEdit) => {
    setVisibleVNet(true);
  };
  const handleVNetsClickClose = (dataItem, isEdit) => {
    setVisibleVNet(false);
  };

  const handleAddEditClickClose = () => {
    setVisible(false);
    SetInstanceData(null);
    setEditMode(false);
    setIsValid(false);
  };

  const handleUpdateInstanceConnectionClickOpen = (dataItem) => {
    SetConnectionInstanceData({ instanceid: dataItem.InstanceId, instancename: dataItem.InstanceName, key: dataItem.InstanceName });
    setVisibleInstanceConnection(true);
  };

  const handleUpdateInstanceConnectionClickClose = () => {
    setVisibleInstanceConnection(false);
    SetConnectionInstanceData(null);
  };

  const checkIsValid = () => {
    debugger;
    if (
      instanceData?.InstanceName !== undefined &&
      instanceData?.InstanceName?.length > 0 &&
      instanceData?.InstanceURL !== undefined &&
      instanceData?.InstanceURL?.length > 0 &&
      instanceData?.DBName !== undefined &&
      instanceData?.DBName?.length > 0 &&
      instanceData?.DBRepoVersion !== undefined &&
      instanceData?.DBRepoVersion?.length > 0 &&
      instanceData?.ZohoURL !== undefined &&
      instanceData?.ZohoURL?.length > 0 &&
      instanceData?.Timezone !== undefined &&
      instanceData?.Timezone?.length > 0 &&
      instanceData?.CountryName !== undefined &&
      instanceData?.CountryName?.length > 0 &&
      instanceData?.Region !== undefined &&
      instanceData?.Region?.length > 0

    ) {
      setIsValid(true);
    } else setIsValid(false);
  };

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <section>
        <p>
          InstanceURL: <strong>{dataItem.InstanceURL}</strong>
        </p>
        <p>
          DBName: <strong>{dataItem.DBName}</strong>
        </p>
        <p>
          DBRepoVersion: <strong>{dataItem.DBRepoVersion}</strong>
        </p>
        <p>
          PlatformName: <strong>{dataItem.PlatformName}</strong>
        </p>
        <p>
          ProductStream: <strong>{dataItem.ProductStreamName}</strong>
        </p>
        <p>
          ZohoURL: <strong>{dataItem.ZohoURL}</strong>
        </p>
        <p>
          Storage Account: <strong>{dataItem.StorageAccountName}</strong>
        </p>
        <p>
          V-Net: <strong >{dataItem.VNetName != '' ? <Button className="ml-auto px-2" onClick={() => handleInfraDocClickOpen(dataItem)} content><FluidIcon size="medium" className='mr-1' />{dataItem.VNetName}</Button> : <></>}</strong>

        </p>
        <p>
          Currency: <strong>{dataItem.CurrencyName}</strong>
        </p>
        {
          props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) ?
            <Checkbox
              styles={{ marginRight: "10px" }}
              toggle
              labelPosition="start"
              label="SyncEnabled:"
              checked={dataItem.SyncEnabled}
              onClick={(event, isChecked) => {
                dataItem.SyncEnabled = isChecked.checked;
                props.acquireToken((token) =>
                  authSuccessUpdateTogglesCallback(token, dataItem)
                );
              }}
            />
            :
            props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.Configuration.Read")) ?
              <Checkbox
                styles={{ marginRight: "10px" }}
                toggle
                labelPosition="start"
                label="SyncEnabled:"
                checked={dataItem.SyncEnabled}
                disabled
              />
              : <></>
        }
      </section>
    );
  };

  const DetailComponentLg = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <table>
        <tbody>
          <tr>
            <td>DBName: <strong>{dataItem.DBName}</strong></td>
            <td>DBRepoVersion: <strong>{dataItem.DBRepoVersion}</strong></td>
            <td>Currency: <strong>{dataItem.CurrencyName}</strong></td>
            <td>V-Net: <strong >{dataItem.VNetName != '' ? <Button className="ml-auto px-2" onClick={() => handleInfraDocClickOpen(dataItem)} ><FluidIcon size="large" className='mr-1' primary /> {dataItem.VNetName}</Button> : <></>}</strong></td>
            <td>Storage Account: <strong>{dataItem.StorageAccountName}</strong></td>
            <td>Generate Bill On Day: <strong>{dataItem?.GenerateBillOnDay}</strong></td>
            <td>Business Hour Rule Applied: <strong>{dataItem?.IsBusinessHourRuleApplied ? "Yes" : "No"}</strong></td>
            <td>Business Hour Profile: <strong>{dataItem?.BusinessHourProfileName}</strong></td>
          </tr>
        </tbody>
      </table>
    );
  };

  const cellIsDBConnectionConfigured = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={props1.dataItem?.isDBConnectionConfigured ? <CheckmarkCircleIcon /> : <CloseIcon />}
          text
          onClick={() => handleUpdateInstanceConnectionClickOpen(props1.dataItem)}
        />
      </td>
    );
  };
  const cellIsDBConnectionConfiguredDisabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={props1.dataItem?.isDBConnectionConfigured ? <CheckmarkCircleIcon /> : <CloseIcon />}
          text
          disabled
        />
      </td>
    );
  };
  const CellCheckboxSyncEnabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          disabled={!props1.dataItem.isDBConnectionConfigured}
          onChange={(e, { checked }) => {
            props1.dataItem.SyncEnabled = checked;
            props.acquireToken((token) =>
              authSuccessUpdateTogglesCallback(token, props1.dataItem)
            );
          }}
          checked={props1.dataItem.SyncEnabled}
        />
      </td>
    );
  };
  const CellCheckboxSyncEnabledDisabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          disabled
          checked={props1.dataItem.SyncEnabled}
        />
      </td>
    );
  };
  const CellCheckboxAlertsDisabledEnabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          disabled={!props1.dataItem.isDBConnectionConfigured}
          onChange={(e, { checked }) => {
            props1.dataItem.AlertsDisabled = checked;
            props.acquireToken((token) =>
              authSuccessUpdateTogglesCallback(token, props1.dataItem)
            );
          }}
          checked={props1.dataItem.AlertsDisabled}
        />
      </td>
    );
  };
  const CellCheckboxAlertsDisabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox toggle disabled checked={props1.dataItem.AlertsDisabled} />
      </td>
    );
  };
  const CellCheckboxAutoTestCallPlace = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          onChange={(e, { checked }) => {
            props1.dataItem.AutomaticTestCallPlacement = checked;
            props.acquireToken((token) =>
              authSuccessUpdateTogglesCallback(token, props1.dataItem)
            );
          }}
          checked={props1.dataItem.AutomaticTestCallPlacement}
        />
      </td>
    );
  };

  const CellCheckboxAutoTestCallPlaceDisabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          disabled
          checked={props1.dataItem.AutomaticTestCallPlacement}
        />
      </td>
    );
  };

  const CellSPAccessCol = (prop) => {
    return (
      <CellSPAccess {...prop} acquireToken={props.acquireToken} />
    );
  }

  const CellCheckboxIsMonitoringEnabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          onChange={(e, { checked }) => {
            props1.dataItem.IsMonitoring = checked;
            props.acquireToken((token) =>
              authSuccessUpdateTogglesCallback(token, props1.dataItem)
            );
          }}
          checked={props1.dataItem.IsMonitoring}
        />
      </td>
    );
  };
  const CellCheckboxIsMonitoringEnabledDisabled = (props1) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Checkbox
          toggle
          disabled
          checked={props1.dataItem.IsMonitoring}
        />
      </td>
    );
  };

  /* const RenderTooltipCell = (props) => {
    return (
      <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Tooltip
          content={props.dataItem[props.field]}
          trigger={<span>{props.dataItem[props.field]}</span>}
        />
      </td>
    );
  }; */

  /* const expandChange = (event) => {
    const currentId = event.dataItem.InstanceId; // Assuming InstanceId is unique
    setExpandedRowId(prevId => (prevId === currentId ? null : currentId)); // Toggle between expanded and collapsed
}; 

  const CopyAndResizeCell = (props) => {
    const [isResized, setIsResized] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(props.dataItem[props.field]);
      alert('Copied to clipboard!'); // Optional: show user feedback
    };

    const handleClick = () => {
      setIsResized((prev) => !prev);  // Toggle the resizing state
    };

    const cellStyle = isResized ? { whiteSpace: 'normal' } : { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

    return (
      <td style={cellStyle} onClick={handleClick}>
        <Tooltip
          content={props.dataItem[props.field]}
          trigger={<span>{props.dataItem[props.field]}</span>}
        />
        <Button
          onClick={(e) => { e.stopPropagation(); handleCopy(); }}
          secondary // Optional: use secondary button style
        >
          Copy
        </Button>
      </td>
    );
  };*/

  return isLoaded ? (
    <WindowContext.Consumer>
      {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                  <div className="f-size-18">INSTANCES</div>
                  <div>
                    {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" || p == "Settings.Read" || p == "Settings.Configuration.Read")) &&
                      <Button content="VNets" primary title="Virtual Networks" onClick={handleVNetsClickOpen} className="ml-auto" />
                    }
                    {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) &&
                      <Button icon={<AddIcon />} iconPosition="before" content="Add Instance" primary title="Add New Instance" onClick={handleAddEditClickOpen} className="ml-2" />
                    }</div>
                </div>
                <div>
                  {visibleVNet && (
                    <Dialog title={"Azure VNets"}
                      onClose={handleVNetsClickClose}
                    >
                      <Form onSubmit={submitHandler}>

                        <div >
                          {
                            azureVNet ?
                              (
                                <Grid
                                  style={{
                                    height: "356px", width: '500px'
                                  }}
                                  // pageable={true}
                                  pageable={{
                                    buttonCount: 3
                                  }}
                                  sortable={true}
                                  data={process(azureVNet, dataState)}
                                  {...dataState}
                                  onDataStateChange={(e) => {
                                    setDataState(e.dataState);
                                  }}
                                  onExpandChange={expandChange}

                                  expandField="expanded"

                                  rowRender={rowRender}
                                >
                                  {/* <Column field="VNetId" title="VNet Id" width={200} locked={true}  /> */}
                                  <Column field="VNetName" title="VNet Name" width={200} locked={true} />
                                  <Column field="VNetTypeId" title="VNet Type" cell={CellVNetTypeCol} width={200} />
                                </Grid>
                              )
                              :
                              <Loader />
                          }
                        </div>


                        <DialogActionsBar>

                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleVNetsClickClose}
                          >
                            {" "}
                            Cancel
                          </button>
                        </DialogActionsBar>
                      </Form>

                    </Dialog>

                  )}
                </div>

                <div>
                  {visibleInfraDoc && (
                    <Dialog title={`Infrastructure Diagram - ${infraDocInst?.InstanceName}`}
                      onClose={handleInfraDocClickClose}
                      className="k-dialog-infraDoc"
                    >

                      <Form onSubmit={submitHandler}>

                        <div style={{ height: "100%", width: "100%" }} id='infra-doc-container'>
                          <InfraDocsReactFlow  {...props} InfraDocObj={infraDocInst} />
                        </div>
                        <DialogActionsBar>

                          {/* <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleInfraDocClickClose}
                          >
                            {" "}
                            Cancel
                          </button> */}
                        </DialogActionsBar>
                      </Form>

                    </Dialog>

                  )}
                </div>


                <div>
                  {visible && (
                    <Dialog
                      title={
                        editMode
                          ? "Update Instance Details"
                          : "Add New Instance"
                      }
                      onClose={handleAddEditClickClose}
                    >
                      <Form onSubmit={submitHandler}>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormInput
                              label="Instance Name"
                              name="InstanceName"
                              id="InstanceName-inline"
                              fluid
                              required
                              disabled={editMode}
                              value={instanceData?.InstanceName}
                              onChange={(value) => {
                                if (!editMode) {
                                  SetInstanceData({
                                    ...instanceData,
                                    InstanceName: value.target.value,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormInput
                              label="Instance URL"
                              name="InstanceURL"
                              id="InstanceURL-inline"
                              fluid
                              required
                              value={instanceData?.InstanceURL}
                              onChange={(value) => {
                                SetInstanceData({
                                  ...instanceData,
                                  InstanceURL: value.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormInput
                              label="DB Name"
                              name="DBName"
                              id="DBName-inline"
                              fluid
                              required
                              value={instanceData?.DBName}
                              onChange={(value) => {
                                SetInstanceData({
                                  ...instanceData,
                                  DBName: value.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `DB Repo Version*`,
                                id: "DBRepo-Version-inline",
                              }}
                              items={dbRepoVersions}
                              aria-labelledby={"DBRepoVersion-inline"}
                              placeholder="Choose DB Repo Version"
                              required
                              autosize="true"
                              value={
                                instanceData != null &&
                                  !(
                                    instanceData.DBRepoVersion == null ||
                                    instanceData.DBRepoVersion == ""
                                  )
                                  ? {
                                    header: instanceData?.DBRepoVersion,
                                    value: instanceData?.DBRepoVersion,
                                  }
                                  : null
                              }
                              onChange={handleDBRepoChange}
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormInput
                              label="Zoho Link"
                              name="ZohoURL"
                              id="ZohoURL-inline"
                              fluid
                              required
                              value={instanceData?.ZohoURL}
                              onChange={(value) => {
                                SetInstanceData({
                                  ...instanceData,
                                  ZohoURL: value.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Platform Name`,
                                id: "Platform-Name-inline",
                              }}
                              items={platforms?.map((obj) => {
                                return { header: obj.PlatformName, value: obj.Id };
                              })}
                              aria-labelledby={"PlatformName-inline"}
                              placeholder="Choose Platform"
                              required
                              autosize="true"
                              value={instanceData?.PlatformName}
                              onChange={handlePlatformChange}
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Azure Virtual Network`,
                                id: "Virtual-Network-inline",
                              }}
                              items={azureVNet?.map((obj) => {
                                return { header: obj.VNetName, value: obj.VNetId };
                              })}
                              aria-labelledby={"VirtualNetwork-inline"}
                              placeholder="Choose Virtual Network"
                              required
                              autosize="true"
                              value={instanceData?.VNetName}
                              onChange={handleVNetChange}
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Product Stream`,
                                id: "Product-Stream-inline",
                              }}
                              items={productStreams?.map((obj) => {
                                return {
                                  header: obj.ProductStreamName,
                                  value: obj.Id,
                                };
                              })}
                              aria-labelledby={"Product-Stream-inline"}
                              placeholder="Choose Product Stream"
                              required
                              autosize="true"
                              value={instanceData?.ProductStreamName}
                              onChange={handleProductStreamChange}
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Timezone*`,
                                id: "Timezone-inline",
                              }}
                              items={timezones?.map((obj) => {
                                return {
                                  header: obj.TimezoneName,
                                  value: obj.Id,
                                  key: obj.Id,
                                };
                              })}
                              aria-labelledby={"Timezone-inline"}
                              placeholder="Choose Timezone"
                              required
                              autosize="true"
                              value={instanceData?.Timezone}
                              onChange={handleTimezoneChange}
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Country*`,
                                id: "Country-inline",
                              }}
                              items={countries?.map((obj) => {
                                return {
                                  header: obj.CountryName,
                                  value: obj.CountryId,
                                  key: obj.CountryId
                                };
                              })}
                              aria-labelledby={"CountryName-inline"}
                              placeholder="Choose Country"
                              required
                              autosize="true"
                              value={instanceData?.CountryName}
                              onChange={handleCountryChange}
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Region*`,
                                id: "Region-inline",
                              }}
                              items={regions?.map((obj) => {
                                return {
                                  header: obj.RegionName,
                                  value: obj.Id,
                                };
                              })}
                              aria-labelledby={"Region-inline"}
                              placeholder="Choose Region"
                              required
                              autosize="true"
                              value={instanceData?.Region}
                              onChange={handleRegionChange}
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Currency`,
                                id: "Currency-inline",
                              }}
                              items={currencies?.map((obj) => {
                                return {
                                  header: obj.CurrencyName,
                                  value: obj.Id,
                                };
                              })}
                              aria-labelledby={"Currency-inline"}
                              placeholder="Choose Currency"
                              required
                              autosize="true"
                              value={instanceData?.CurrencyName}
                              onChange={handleCurrencyChange}
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Generate Bill On Day`,
                                id: "billPeriod-inline",
                              }}
                              items={billPeriods?.map((obj) => {
                                return {
                                  header: String(obj.GenerateBillOnDay),
                                  value: obj.BillPeriodId,
                                };
                              })}
                              aria-labelledby={"billPeriod-inline"}
                              placeholder="Choose Billing Period"
                              required
                              autosize="true"
                              value={instanceData?.GenerateBillOnDay}
                              onChange={handleBillPeriodChange}
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Storage Account`,
                                id: "StorageAccount-inline",
                              }}
                              items={storageAccounts.map((obj) => {
                                return {
                                  header: obj.StorageAccountName,
                                  value: obj.Id,
                                };
                              })}
                              aria-labelledby={"StorageAccountName-inline"}
                              placeholder="Choose Storage Account"
                              required
                              autosize="true"
                              value={instanceData?.StorageAccountName}
                              onChange={handleStorageAccountNameChange}
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 mb-3 d-flex align-items-center'>
                            <FormCheckbox
                              label="Disable Monitoring Alerts"
                              id="AlertsDisabled"
                              value={instanceData?.AlertsDisabled}
                              checked={instanceData?.AlertsDisabled}
                              onChange={(e, { checked }) => {
                                handleAlertsDisabledChange(checked);
                              }}
                            />
                            <Tooltip
                              pointing
                              trigger={<InfoIcon />}
                              content="This will stop creating incidents."
                            />
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='col-12 col-md-6 pt-3 mb-3 d-flex'>
                            <FormCheckbox
                              label="Business Hour Rule Applied For Alerts"
                              id="IsBusinessHourRuleApplied"
                              value={instanceData?.IsBusinessHourRuleApplied}
                              checked={instanceData?.IsBusinessHourRuleApplied}
                              onChange={(e, { checked }) => {
                                handleIsBusinessHourRuleAppliedChange(checked);
                              }}

                            />
                            <Tooltip
                              pointing
                              trigger={<InfoIcon />}
                              content="If applied, the incident level will create on business hours only."
                            />
                          </div>
                          <div className='col-12 col-md-6 mb-3'>
                            <FormDropdown
                              label={{
                                content: `Business Hour Profile`,
                                id: "BusinessHourProfileId-inline",
                              }}
                              items={businessHourProfiles.map((obj) => {
                                return {
                                  header: obj.Name,
                                  value: obj.Id,
                                };
                              })}
                              aria-labelledby={"BusinessHourProfileId-inline"}
                              placeholder="Choose Busines Hour Profile"

                              autosize="true"
                              value={instanceData?.BusinessHourProfileName}
                              onChange={handleBusinessHourProfileChange}
                              disabled={!instanceData?.IsBusinessHourRuleApplied}
                            />
                          </div>
                        </div>

                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addEditSubmitClickHandler}
                            disabled={isValid === false}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddEditClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  )}
                </div>
                <div>
                  {visibleInstanceConnection && (
                    <Dialog
                      title={"Update Instance Connection Details"}
                      onClose={handleUpdateInstanceConnectionClickClose}
                    >
                      <Form onSubmit={submitHandler}>
                        <FormInput
                          label="Instance Name"
                          name="InstanceName"
                          id="InstanceName-inline"
                          fluid
                          required
                          value={instanceConnectionData?.instancename}
                          onChange={(value) => {
                            SetConnectionInstanceData({
                              ...instanceConnectionData,
                              instancename: value.target.value,
                            });
                          }}
                        />
                        <FormInput
                          placeholder="Primary DB IP for Shared Client/ Default DataSource from connection string"
                          label="Data Source"
                          name="DataSource"
                          id="datasource-inline"
                          fluid
                          required
                          value={instanceConnectionData?.datasource}
                          onChange={(value) => {
                            SetConnectionInstanceData({
                              ...instanceConnectionData,
                              datasource: value.target.value,
                            });
                          }}
                        />
                        <FormInput
                          label="User Id"
                          name="UserId"
                          id="userid-inline"
                          fluid
                          required
                          value={instanceConnectionData?.user}
                          onChange={(value) => {
                            SetConnectionInstanceData({
                              ...instanceConnectionData,
                              user: value.target.value,
                            });
                          }}
                        />
                        <FormInput
                          label="Password"
                          name="Password"
                          id="password-inline"
                          fluid
                          required
                          value={instanceConnectionData?.securestring}
                          onChange={(value) => {
                            SetConnectionInstanceData({
                              ...instanceConnectionData,
                              securestring: value.target.value,
                            });
                          }}
                        />
                        <FormInput
                          label="Hub Client Address"
                          name="HubClientAddress"
                          id="hubclientaddress-inline"
                          fluid
                          required
                          value={instanceConnectionData?.hubclientaddress}
                          onChange={(value) => {
                            SetConnectionInstanceData({
                              ...instanceConnectionData,
                              hubclientaddress: value.target.value,
                            });
                          }}
                        />
                        <FormInput
                          label="Auth Token"
                          name="Auth"
                          id="authtoken-inline"
                          fluid
                          required
                          value={instanceConnectionData?.authtoken}
                          onChange={(value) => {
                            SetConnectionInstanceData({
                              ...instanceConnectionData,
                              authtoken: value.target.value,
                            });
                            //debugger;
                          }}
                        />
                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={instanceConnectionUpdateSubmitClickHandler}
                            disabled={
                              !(instanceConnectionData != null
                                && instanceConnectionData?.key?.length > 0
                                && instanceConnectionData?.instancename?.length > 0
                                && instanceConnectionData?.datasource?.length > 0
                                && instanceConnectionData?.user?.length > 0
                                && instanceConnectionData?.securestring?.length > 0
                                && instanceConnectionData?.authtoken?.length > 0
                                && instanceConnectionData?.hubclientaddress?.length > 0)
                            }
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleUpdateInstanceConnectionClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e, { name, value }) => {
                      filterGridData(value);
                    }}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className="grid-with-search">
                  <Grid
                    style={{
                      height: "100%",
                    }}
                    // pageable={true}
                    pageable={{
                      buttonCount: 3
                    }}
                    sortable={true}
                    data={process(portals, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    detail={
                      context.mediaCategory.sm
                        ? DetailComponentSm
                        : DetailComponentLg
                    }
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                    <Column
                      field="InstanceName"
                      title="Instance Name"
                      cell={RenderTooltipCell}
                      width={250} // Adjust as needed
                    />
                    {/* <Column field="InstanceName" title="Instance Name" width={150} cell={(props) => (
                      <CopyAndResizeCell
                        {...props}
                        isExpanded={expandedRowId === props.dataItem.InstanceId} // Pass current expanded state
                        onClick={() => setExpandedRowId(props.dataItem.InstanceId)} // Click handler
                      />
                    )} /> */}
                    {!context.mediaCategory.sm && (
                      <Column
                        field="InstanceURL"
                        title="Instance URL"
                        width={280}
                        cell={RenderTooltipCell}
                      />
                    )}
                    {/* {!context.mediaCategory.sm && (
                      <Column field="DBName" title="DB Name" width={170} />
                    )} */}
                    {!context.mediaCategory.sm && (
                      <Column width={120} field="WebApplicationVersion" title="ICS Version" />
                    )}
                    {!context.mediaCategory.sm && (
                      <Column width={150} field="PlatformName" title="PlatformName" />
                    )}
                    {!context.mediaCategory.sm && (
                      <Column
                        width={150}
                        field="ProductStreamName"
                        title="Product Stream"
                      />
                    )}
                    {!context.mediaCategory.sm && (
                      <Column
                        field="ZohoURL"
                        title="Zoho Link"
                        cell={CellZohoURL}
                        width={100} filterable={false} sortable={false}
                      />
                    )}
                    {!context.mediaCategory.sm && (
                      <Column
                        field="InstanceId"
                        title="SP Access"
                        cell={CellSPAccessCol}
                        width={100} filterable={false} sortable={false}
                      />
                    )}
                    {!context.mediaCategory.sm ?
                      props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) ?
                        <Column
                          field="isDBConnectionConfigured"
                          title="DB Connection"
                          cell={cellIsDBConnectionConfigured}
                          width={150} filterable={false} sortable={false}
                        />
                        : props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.Configuration.Read")) ?
                          <Column
                            field="isDBConnectionConfigured"
                            title="DB Connection"
                            cell={cellIsDBConnectionConfiguredDisabled}
                            width={150} filterable={false} sortable={false}
                          />
                          : <></> : <></>
                    }
                    {!context.mediaCategory.sm ?
                      props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) ?
                        (
                          <Column
                            field="SyncEnabled"
                            title="Sync"
                            cell={CellCheckboxSyncEnabled}
                            width={120} filterable={false} sortable={false}
                          />
                        ) : props.user?.Permissions?.some(
                          (p) =>
                            p == "Settings.Read" ||
                            p == "Settings.Configuration.Read"
                        ) ? (
                          <Column
                            field="SyncEnabled"
                            title="Sync"
                            cell={CellCheckboxSyncEnabledDisabled}
                            width={120} filterable={false} sortable={false}
                          />
                        ) : (
                          <></>
                        )
                      : (
                        <></>
                      )}
                    {!context.mediaCategory.sm ?
                      props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite")) ?
                        (
                          <Column
                            field="IsMonitoring"
                            title="Monitoring Enabled"
                            cell={CellCheckboxIsMonitoringEnabled}
                            width={160} filterable={false} sortable={false}
                          />
                        ) : props.user?.Permissions?.some(
                          (p) =>
                            p == "Settings.Read" ||
                            p == "Settings.Configuration.Read"
                        ) ? (
                          <Column
                            field="IsMonitoring"
                            title="Monitoring Enabled"
                            cell={CellCheckboxIsMonitoringEnabledDisabled}
                            width={160} filterable={false} sortable={false}
                          />
                        ) : (
                          <></>
                        )
                      : (
                        <></>
                      )}
                    {!context.mediaCategory.sm ? (
                      props.user?.Permissions?.some(
                        (p) =>
                          p == "Settings.ReadWrite" ||
                          p == "Settings.Configuration.ReadWrite"
                      ) ? (
                        <Column
                          field="AlertsDisabled"
                          title="Alerts Disabled"
                          cell={CellCheckboxAlertsDisabledEnabled}
                          width={120}
                          filterable={false}
                          sortable={false}
                        />
                      ) : props.user?.Permissions?.some(
                        (p) =>
                          p == "Settings.Read" ||
                          p == "Settings.Configuration.Read"
                      ) ? (
                        <Column
                          field="AlertsDisabled"
                          title="Alerts Disabled"
                          cell={CellCheckboxAlertsDisabled}
                          width={120}
                          filterable={false}
                          sortable={false}
                        />
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {!context.mediaCategory.sm ? (
                      props.user?.Permissions?.some(
                        (p) =>
                          p == "Settings.ReadWrite" ||
                          p == "Settings.Configuration.ReadWrite"
                      ) ? (
                        <Column
                          field="AutomaticTestCallPlacement"
                          title="TestCall"
                          cell={CellCheckboxAutoTestCallPlace}
                          width={90} filterable={false} sortable={false}
                        />
                      ) : props.user?.Permissions?.some(
                        (p) =>
                          p == "Settings.Read" ||
                          p == "Settings.Configuration.Read"
                      ) ? (
                        <Column
                          field="AutomaticTestCallPlacement"
                          title="TestCall"
                          cell={CellCheckboxAutoTestCallPlaceDisabled}
                          width={90} filterable={false} sortable={false}
                        />
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}

                    {props.user?.Permissions?.some(
                      (p) =>
                        p == "Settings.ReadWrite" ||
                        p == "Settings.Configuration.ReadWrite"
                    ) && (
                        <Column
                          field=""
                          title="Actions"
                          cell={ActionIconPortals}
                          width={100}
                        />
                      )}
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
          <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={() => {
              setDeleteConfirmationDialog(false);
              setDeleteInstanceObj(null);
            }}
            onConfirm={() => removeData()}
            open={openDeleteConfirmationDialog}
            backdrop
            header="Action confirmation"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                setDeleteConfirmationDialog(false);
                setDeleteInstanceObj(null);
              },
            }}
            content={`Are you sure to delete Instance: ${deleteInstanceObj?.InstanceName}?`}
          />
        </div>
      )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

export { SettingsPortals };
