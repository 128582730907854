import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog as FluentDialog,
  Text,
  Button,
  Input,
  Form,
  FormInput,
  Loader,
  EditIcon,
  TrashCanIcon,
  Checkbox,
  Datepicker,
  Card,
  CardBody,
  CardHeader
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, CloseIcon } from "@fluentui/react-icons-northstar";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import debounce from 'lodash.debounce';
import { RenderTooltipCell } from "../../shared/appcomponents/TooltipWithCopy";

const SettingsCertificateStatus = (props) => {
  const [certificates, setCertificates] = useState([]);
  const [certificateData, setCertificateData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCertNameValid, setIsCertNameValid] = useState(true);
  const [isDNSValid, setIsDNSValid] = useState(true);
  const [isIPValid, setIsIPValid] = useState(true);
  const [isCertNameDisabled, setIsCertNameDisabled] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteCertificateObj, setDeleteCertificateObj] = useState(null);
  const [dataState, setDataState] = useState({
    sort: [{ field: "Id", dir: "asc" }],
    take: 10,
    skip: 0,
    filter: {
      logic: "or",
      filters: [],
    },
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
      SettingsService.GetCertificates(token).then((response) => {
        if (response && response.status === 200) {
          setCertificates(response.data);
          setIsLoaded(true);
          setDataState({ ...dataState, data: response.data });
        } else {
          props.viewStatus.InformChildPageStatus(response.status || 500);
          setIsLoaded(true);
        }
      });
    }
  };

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [
        { field: "Id", operator: "contains", value: value },
        { field: "CertName", operator: "contains", value: value },
        { field: "DNS", operator: "contains", value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const handleAddEditClickOpen = (dataItem, isEdit) => {
    setIsCertNameValid(true);
    setIsDNSValid(true);
    setIsIPValid(true);
    setVisible(true);

    if (isEdit) {
      setCertificateData({
        ...dataItem,
        ExpiryDate: dataItem.ExpiryDate ? new Date(dataItem.ExpiryDate) : new Date(),
        LastChecked: dataItem.LastChecked ? new Date(dataItem.LastChecked) : new Date(),
      });
      setEditMode(true);
      setIsCertNameDisabled(true);
    } else {
      setCertificateData({
        Id: 0,
        CertName: "",
        DNS: "",
        IP: "",
        ExpiryDate: new Date(),
        LastChecked: new Date(),
        IsActive: false,
      });
      setEditMode(false);
      setIsCertNameDisabled(false);
    }
  };


  const handleAddEditClickClose = () => {
    setVisible(false);
    setCertificateData(null);
    setEditMode(false);
    setIsCertNameValid(true);
    setIsDNSValid(true);
    setIsIPValid(true);
  };


  const handleDeleteClickOpen = (dataItem) => {
    setDeleteCertificateObj(dataItem);
    setDeleteConfirmationDialog(true);
  };

  const handleDeleteClose = () => {
    setDeleteConfirmationDialog(false);
    setDeleteCertificateObj(null);
  };
  const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1?[0-9]?[0-9])$/;

  const handleSave = () => {
    console.log("Data being sent:", certificateData);
    let isValid = true;

    if (!certificateData.CertName) {
      setIsCertNameValid(false);
      isValid = false;
    } else {
      setIsCertNameValid(true);
    }

    if (!certificateData.DNS) {
      setIsDNSValid(false);
      isValid = false;
    } else {
      setIsDNSValid(true);
    }

    if (!certificateData.IP || !ipRegex.test(certificateData.IP)) {
      setIsIPValid(false);
      isValid = false;
    } else {
      setIsIPValid(true);
    }

    if (!isValid) return;

    if (isSaving) return;
    setIsSaving(true);
    props.acquireToken((token) => {
      const serviceCall = editMode
        ? SettingsService.UpdateCertificate(certificateData, token)
        : SettingsService.AddCertificate(certificateData, token);
      serviceCall.then((response) => {
        if (response && response.status === 200) {
          authSuccessCallback(token);
          handleAddEditClickClose();
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        } else {
          props.viewStatus.DisplayOperationStatus(response.status || 500);
        }
        setIsSaving(false);
      }).catch(() => {
        setIsSaving(false);
      });
    });
  };

  const handleDelete = () => {
    // Optimistically update the certificates state
    const updatedCertificates = certificates.filter(cert => cert.Id !== deleteCertificateObj.Id);
    setCertificates(updatedCertificates);

    props.acquireToken((token) => {
      SettingsService.DeleteCertificate(deleteCertificateObj.Id, token)
        .then((response) => {
          if (response && response.status === 200) {
            // Successfully deleted the certificate, update the grid state
            setDataState({ ...dataState, data: updatedCertificates });
            handleDeleteClose();
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } else {
            // If the delete request failed, revert the state change
            setCertificates(certificates);
            props.viewStatus.DisplayOperationStatus(response.status || 500);
          }
        })
        .catch(() => {
          // If an error occurred, revert the state change
          setCertificates(certificates);
        });
    });
  };

  const processedData = useMemo(() => process(certificates, dataState), [certificates, dataState]);

  const handleToggleChange = (dataItem) => {
    const updatedCertificate = { ...dataItem, IsActive: !dataItem.IsActive };
    props.acquireToken((token) => {
      SettingsService.UpdateCertificate(updatedCertificate, token).then((response) => {
        if (response && response.status === 200) {
          authSuccessCallback(token);
          props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
        } else {
          props.viewStatus.DisplayOperationStatus(response.status || 500);
        }
      });
    });
  };

  const handleDateChange = (e, data) => {
    const localDate = new Date(data.value);
    setCertificateData({ ...certificateData, ExpiryDate: localDate });
  };

  const handleSaveDebounced = debounce(handleSave, 300);

  return isLoaded ? (
    <WindowContext.Consumer>
      {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                  <div className="f-size-18">Certificate Status</div>
                  {props.user?.Permissions?.some((p) => p === "Settings.ReadWrite" || p === "Settings.Configuration.ReadWrite") && (
                    <Button
                      icon={<AddIcon />}
                      iconPosition="before"
                      content="Add Certificate"
                      primary
                      onClick={() => handleAddEditClickOpen(null, false)}
                      className="ml-auto"
                    />
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e) => filterGridData(e.target.value)}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className="grid-with-search">
                  <Grid
                    style={{
                      height: "100%",
                    }}
                    pageable={{
                      buttonCount: 3,
                    }}
                    sortable={true}
                    data={processedData.data}  // Use the memoized processed data
                    total={processedData.total}  // Total count from processed data
                    skip={dataState.skip}  // Skip value from the current state
                    take={dataState.take}  // Take value from the current state
                    sort={dataState.sort}  // Sort value from the current state
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);  // Update the dataState with the new sorting or pagination
                    }}
                  >
                    <Column field="CertName" title="Certificate Name" width="150px" cell={RenderTooltipCell} />
                    <Column field="DNS" title="DNS" width="200px" cell={RenderTooltipCell}/>
                    <Column field="IP" title="IP" width="100px" cell={RenderTooltipCell}/>
                    <Column field="ExpiryDate" title="Expiry Date" width="100px" />
                    <Column field="LastChecked" title="Last Checked" width="100px" />
                    <Column
                      field="IsActive"
                      title="IsActive"
                      width="80px"
                      cell={(props) => (
                        <td style={{ textAlign: "center" }}>
                          <Checkbox
                            toggle
                            checked={props.dataItem.IsActive}
                            onChange={() => handleToggleChange(props.dataItem)}
                          />
                        </td>
                      )}
                    />
                    {props.user?.Permissions?.some((p) => p === "Settings.ReadWrite" || p === "Settings.Configuration.ReadWrite") && (
                      <Column
                        field=""
                        title="Actions"
                        style={{ textAlign: "center" }}
                        width={100}
                        cell={(props) => (
                          <td style={{ textAlign: "center" }}>
                            <Button
                              styles={{ height: 'auto' }}
                              iconOnly
                              size="small"
                              icon={<EditIcon />}
                              text
                              onClick={() => handleAddEditClickOpen(props.dataItem, true)}
                            />
                            <Button
                              styles={{ height: 'auto' }}
                              iconOnly
                              size="small"
                              icon={<TrashCanIcon />}
                              text
                              onClick={() => handleDeleteClickOpen(props.dataItem)}
                            />
                          </td>
                        )}
                      />
                    )}
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
          <div>
            {visible && (
              <Dialog
                className="k-dialog-md"
                title={editMode ? "Edit Certificate" : "Add Certificate"}
                onClose={handleAddEditClickClose}
              >
                <Form
                // onSubmit={submitHandler}
                >
                  <FormInput
                    label={isCertNameValid ? "Certificate Name" : <Text color="red" content="Certificate Name Required" />}
                    required
                    id="alertId-inline"
                    fluid
                    value={certificateData?.CertName || ""}
                    onChange={(e) => {
                      setCertificateData({ ...certificateData, CertName: e.target.value });
                      setIsCertNameValid(e.target.value !== "");
                    }}
                    error={!isCertNameValid}
                    disabled={isCertNameDisabled}
                    placeholder="SSL Cert 1"
                  />
                  <FormInput
                    label={isDNSValid ? "DNS" : <Text color="red" content="DNS Required" />}
                    required
                    id="alertId-inline"
                    fluid
                    value={certificateData?.DNS || ""}
                    onChange={(e) => {
                      setCertificateData({ ...certificateData, DNS: e.target.value });
                      setIsDNSValid(e.target.value !== "");

                    }}
                    error={!isDNSValid}
                    placeholder="xyz@xyz.com"
                  />
                  <FormInput
                    label={isIPValid ? "IP" : <Text color="red" content="IP Required" />}
                    required
                    id="alertId-inline"
                    fluid
                    value={certificateData?.IP || ""}
                    onChange={(e) => {
                      setCertificateData({ ...certificateData, IP: e.target.value });
                      setIsIPValid(e.target.value !== "" && ipRegex.test(e.target.value));
                    }}
                    error={!isIPValid}
                    placeholder="192.168.1.1"
                  />
                  <div><label>Expiry Date</label>
                    <Datepicker
                      label="Expiry Date"
                      value={certificateData?.ExpiryDate ? new Date(certificateData.ExpiryDate) : new Date()}
                      onDateChange={handleDateChange}
                    />
                  </div>
                  <label style={{ display: "none" }}>Last Checked Date</label>
                  <Datepicker
                    style={{ display: "none" }}
                    label="Last Checked"
                    value={certificateData?.LastChecked || new Date()}
                    disabled
                  />
                  <div>
                    <label style={{ display: "inline" }} >Active</label>
                    <Checkbox style={{ display: "inline" }}
                      toggle
                      checked={certificateData?.IsActive || false}
                      onChange={(e, data) => setCertificateData({ ...certificateData, IsActive: data.checked })}
                    />
                  </div>

                  <DialogActionsBar>
                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={handleSaveDebounced}
                    // disabled={isValid === false}
                    >
                      {" "}
                      Submit
                    </button>
                    <button
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={handleAddEditClickClose}
                    >
                      {" "}
                      Close
                    </button>
                  </DialogActionsBar>
                </Form>
              </Dialog>
            )}
          </div>
          <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={handleDeleteClose}
            onConfirm={handleDelete}
            open={openDeleteConfirmationDialog}
            backdrop
            header="Confirm Deletion"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                setDeleteConfirmationDialog(false);
                setDeleteCertificateObj(null);
              },
            }}
            content={`Are you sure you want to delete certificate "${deleteCertificateObj?.CertName}" ?`}
          />
        </div>
      )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

export { SettingsCertificateStatus };
