import React, { useState } from 'react'
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Card, Loader, Text, Flex , Tooltip} from '@fluentui/react-northstar';
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { CellIncidentHistory, CellServerGraph, CellAccessIcon, CellDateTime, CellIncidentStatus, CellServerRuleBreached, CellComments, CellServerGraphLabel, CellColTitle, rowRender } from '../../../../shared/appcomponents/custom-grid-cells';
import { INCIDENTLEVELS } from '../../../../shared/appcomponents/enums';
import MonitoringService from '../../../../services/monitoringservice';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from '@progress/kendo-data-query';
import { WindowContext } from "../../../../shared/contexts/Context";
import { useSearchParams, useParams } from 'react-router-dom';
import { OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';


const DbMonitoringAlarmHistorical = (props) => {
  const { id } = useParams();
  const [incidents, setIncidents] = useState(null);
  const [isUpdating, setUpdating] = React.useState(false);
  const [IncidentStatuses, setIncidentStatuses] = useState(null);
  const [AppUsers, setAppUsers] = useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    props.incidents?.Table?.map(m => m.IsUpdating = false);
    setIncidents(props.incidents?.Table);
    setIncidentStatuses(props.incidents?.IncidentStatuses);
    setAppUsers(props.incidents?.AppUsers);
    setLoaded(true);
  }, [props.incidents]);

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "IncidentTimestampUTC",
        dir: "desc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);
    setIncidents(null);
    setIncidentStatuses(null);
    setAppUsers(null);


    props.acquireToken((token) => {
      let _params = {
        instanceId: -1,
        incidentLevelId: -1,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetDbMonitoringIncidents(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setIncidentStatuses(response.IncidentStatuses);
            setAppUsers(response.AppUsers);
            response.Table?.map(m => m.IsUpdating = false);
            setIncidents(response.Table);
          }
          else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }
          setLoaded(true);
        });
      }
    })
  }

  const filterGridData = (value) => {
    let filter = {
      logic: 'or',
      filters: [
        { field: 'InstanceName', operator: 'contains', value: value },
        { field: 'CustomerName', operator: 'contains', value: value },
        { field: 'IncidentId', operator: 'contains', value: value },
        { field: 'IncidentOwner', operator: 'contains', value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };



  const CellIncidentHistoryCol = (prop) => {
    return (

      <CellIncidentHistory {...prop} IncidentSection={4} acquireToken={props.acquireToken} />

    );
  }

  const CellCommentsCol = (prop) => {
    return (
      <td style={!prop.text ? { textAlign: "center" } : {}}>
        {prop.text && <span>{prop.text}&nbsp;</span>}
        <CellComments user={props.user} {...prop} IncidentSection={4} acquireToken={props.acquireToken} />
      </td>
    );
  }

  const expandChange = (event) => {
    let newData = incidents.map((item) => {
      if (item.IncidentId === event.dataItem.IncidentId) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setIncidents(newData);
  };

  const CellIncidentStatusCol = (prop) => {
    return <CellIncidentStatus {...prop} user={props.user}  incidentStatus={IncidentStatuses}
      onChange={() => {
        props.acquireToken((token) => {
          setUpdating(true);
          prop.dataItem.IncidentOwner = props.account.username;
          UpdateIncedent(token, prop.dataItem);
        })
      }}
    />
  }


  const UpdateIncedent = (token, dataItem) => {
    let _params = {
      IncidentSection: 4,
      IncidentId: dataItem.IncidentId,
      IncidentOwner: dataItem.IncidentOwner,
      IncidentStatusId: dataItem.IncidentStatusId,
      IsHistoricalUpdate: "1"
    }

    MonitoringService.UpdateIncident(token, _params).then((response) => {
      if(response && response.status==200){
        response=response.data;
        setUpdating(false);
        if(dataItem.IncidentStatusId!==3){
          let array = [...incidents];
            let index= array.findIndex(element => element.IncidentId === dataItem.IncidentId)
            if (index !== -1) {
                array.splice(index, 1);
                setIncidents(array);
              }
        }
        //props.routeChange('/dashboards/monitoring/infra/alarms?type=history', 'history', 1);
        // RefeshData(null);
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
      }
      else if(response && response.status==401){
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
      }
      else {
        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
      }
      dataItem.IsUpdating=false;    
      if(dataItem.IncidentStatusId===3){
      let arr = [...incidents];
      setIncidents(arr);  }
    });
  }

  const RefeshData = (appliedFilters) => {
    setLoaded(false);
    setIncidents(null);
    setIncidentStatuses(null);
    setAppUsers(null);

    props.acquireToken((token) => {
      let _params = {
        instanceId: -1,
        incidentLevelId: -1,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetDbMonitoringIncidents(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            setIncidentStatuses(response.IncidentStatuses);
            response.Table?.map(m => m.IsUpdating = false);
            setAppUsers(response.AppUsers);
            setIncidents(response.Table);
          } else if(response && response.status==401){
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }

          setLoaded(true);
        });
      }
    })
  }

  const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
    return (
      <table>
        <tbody>
          <tr>
            <td>IncidentId: {dataItem.IncidentId}</td>
            <td>
              <CellServerGraphLabel
                {...props1}
                field={"ServerName"}
                text={"Server Name:"}
              />
            </td>
          </tr>
          <tr>
            <td>Server Type: {dataItem.ServerType}</td>
            <td>Server Rule Breached: {dataItem.ServerRuleBreached}</td>
          </tr>
          <tr>
            <td>
              <CellAccessIcon
                {...props1}
                field={"RDPAccess"}
                text={"RDP Access:"}
              />
            </td>
            <td>
              <CellServerGraph
                {...props1}
                field={"ServerGraph"}
                text={"Server Graph:"}
              />
            </td>
          </tr>
          <tr>
            <td>
              <CellCommentsCol
                {...props1}
                field={"IncidentId"}
                text={"Comments:"}
              />
            </td>
            <td>
              <CellIncidentHistoryCol
                {...props1}
                field={"IncidentId"}
                text={"Incident History:"}
              />
            </td>
          </tr>
          <tr>
            <td>
              <CellDateTime
                {...props1}
                field={"ClearedTimeStampUTC"}
                text={"Cleared TimeStamp UTC:"}
              />
            </td>
            <td>Owner: {dataItem.DisplayName}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const DetailComponentLg = (props1) => {
    return (
      <table>
        <tbody>
          <tr>
            <td>
              <CellAccessIcon
                {...props1}
                field={"RDPAccess"}
                text={"RDP Access:"}
              />
            </td>
            <td>
              <CellCommentsCol
                {...props1}
                field={"IncidentId"}
                text={"Comments:"}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  const RenderTooltipCell = (props) => {
      return (
        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', position: 'relative' }}>
          <Tooltip
            content={props.dataItem[props.field]}
            trigger={<div>{props.dataItem[props.field]}</div>}
          />
        </td>
      );
    };

  console.log(incidents);
  return <WindowContext.Consumer>
    {(context) => (
      <>
        <Flex vAlign='center' fluid className='w-100 mb-2 flex-sm-column'>
          <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
            defaultAppliedFilter={
              { filter: 'Instance', operator: '==', value: id }
            }
            //defaultAppliedFilter={{filter: 'Instance', operator: '==', value: id}} 
            elegibleFilters={['Instance', 'Server', 'ServerType', 'Period', 'Platform', 'Owner', 'IncidentStatus']} />
          <Flex.Item push>
            <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
              <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
            </div>
          </Flex.Item>
        </Flex>
        <div className='w-100 no-bg'>
          {
            isLoaded && incidents ?
              (
                <KendoGrid
                  style={{
                    height: "456px",
                  }}
									// pageable={true}
									pageable={{
										buttonCount: 3
									}}
									sortable={true}
                  data={process(incidents, dataState)}
                  {...dataState}
                  onDataStateChange={(e) => {
                    setDataState(e.dataState);
                  }}
                  // detail={
                  //  context.mediaCategory.sm ? DetailComponentSm : DetailComponentLg
                  // }
                  expandField="expanded"
                  onExpandChange={expandChange}
                  rowRender={rowRender}
                >
                  
                  <Column field="IncidentId" title="Ref#" width={100} locked={true} />
                  <Column field="IncidentTimestampUTC" title="Timestamp UTC" cell={CellDateTime} width={200} locked={true} />
                  <Column field="InstanceName" title="Instance" width={160}  />
                  <Column field="CustomerName" title="Customer" width={160}  />
                  {/* <Column field="ServerName" title="Server Name" cell={CellServerGraphLabel} width={200} />
                  <Column field="ServerType" title="Server Type" width={120} /> */}
                  <Column field="ServerRuleBreached" title="Server Rule Breached" cell={RenderTooltipCell} width={300} />
                  {/* {!context.mediaCategory.sm && (
                  <Column field="RDPAccess" title="RDP Access" cell={CellAccessIcon} />)} */}
                  {/* <Column field="ServerGraph" title="Server Graph" cell={CellServerGraph} width={120} filterable={false} sortable={false} /> */}
                  <Column field="IncidentId" title="Comments" cell={CellCommentsCol} width={110} filterable={false} sortable={false} />
                  <Column field="IncidentId" title="History" cell={CellIncidentHistoryCol} width={70} filterable={false} sortable={false} />
                  <Column field="IncidentStatusId" title="Status" cell={CellIncidentStatusCol} width={150} />
                  {/* <Column field="ClearedTimeStampUTC" title={<CellColTitle title="Cleared Timestamp UTC" />} cell={CellDateTime} width={200} />
                  <Column field="DisplayName" title="Owner" width={150} /> */}
                  <Column field="IncidentLevel" title="Incident Level" width={150} />
                  {/* {!context.mediaCategory.sm && (
                  <Column field="IncidentOwner" title="Owner" cell={CellIncidentOwnerCol}  />)} */}
                </KendoGrid>
              )
              :
              <Loader />
          }
        </div>

      </>
    )}
  </WindowContext.Consumer>
};


export { DbMonitoringAlarmHistorical }