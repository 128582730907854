
import { SearchIcon } from "@fluentui/react-icons-northstar";
import { Input } from '@fluentui/react-northstar';

const GridGlobalSearch = ({ setSearchInput }) => {
    return (
        <Input
            onChange={(e, { name, value }) => {
                setSearchInput(value);
            }}
            inverted
            icon={<SearchIcon className="search-icon" />}
            placeholder="Search..."
            className='border-gray'
        />
    )
}

export default GridGlobalSearch;