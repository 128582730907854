import React, {useState, useEffect} from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormInput, FormDropdown, FormCheckbox, Loader, EditIcon, TrashCanIcon, Alert, Checkbox, Dialog as FluentDialog, Tooltip } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { AddIcon, SearchIcon, CloseIcon, InfoIcon} from '@fluentui/react-icons-northstar'
import { ActionIcon } from '../../shared/appcomponents/custom-grid-cells';
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { RenderTooltipCell } from "../../shared/appcomponents/TooltipWithCopy"
const SettingsVM = (props) => {
    const [vmData, setVMData] = useState([]);
    const [isLoaded, SetLoaded] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [serverData, SetServerData] = useState(null);
    const [visible, setVisible] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [regions, setRegions] = React.useState([]);
    const [serverTypes, setServerTypes] = React.useState([]);
    const [timezones, setTimezones] = React.useState([]);
    const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteServerObj, setDeleteServerObj] = useState(null);

    const addEditSubmitClickHandler = () => {
      props.acquireToken(authSuccessAddEditServerCallback);
      setVisible(false);
      setEditMode(false);
      SetServerData(null);
    };
    
    const expandChange = (event) => {
      let newData = vmData.map((item) => {
        if (item.ServerId === event.dataItem.ServerId) {
          item.expanded = !event.dataItem.expanded;
        }

        return item;
      });
      setVMData(newData);
    };

    const authSuccessAddEditServerCallback = (token) => {
      if (token) {
        if(editMode){
          SettingsService.UpdateServer(token, serverData).then((response) => {
            if(response && response.status==200){
              response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } 
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                      debugger;
                    }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
        }
        else{
          SettingsService.AddServer(serverData, token).then((response) => {
            if(response && response.status==200){
              response=response.data;          
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
          else{
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
        }
      }
    };

    const authSuccessUpdateTogglesCallback = (token, serverData) => {
      if (token) {
        SettingsService.UpdateServer(token, serverData).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
          }
           else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    };

    const [dataState, setDataState] = React.useState({
      sort: [
        {
          field: "InstanceName",
          dir: "asc",
        },
      ],
      take: 10,
      skip: 0,
    });

    const filterGridData = (value) => {
      let filter = {
        logic: "or",
        filters: [
          { field: "DisplayFriendlyName", operator: "contains", value: value },
          { field: "IPAddress", operator: "contains", value: value },
          { field: "ServerTypeName", operator: "contains", value: value },
          { field: "MachineName", operator: "contains", value: value },
          { field: "IHubClientAddress", operator: "contains", value: value },
          { field: "Timezone", operator: "contains", value: value },
        ],
      };
      setDataState({ ...dataState, filter: filter });
    };
    
    useEffect(() => checkIsValid(), [serverData]);

    const authSuccessRemoveServerCallback = (token, serverId) => {
      if (token) {
        let _params = {
          serverId: serverId,
        };
        SettingsService.RemoveServer(token, _params).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          } 
          else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    };

    const submitHandler = e => {
      e.preventDefault();
    }

    const handleRegionChange = (alwaysNull, data) => {
      SetServerData({ ...serverData, Region: data.value.header });
    };

    const handleServerTypeChange = (alwaysNull, data) => {
      SetServerData({...serverData, ServerTypeName : data.value.header});
    };

    const handleTollringServerChange = (checked) => {
      SetServerData({ ...serverData, IsTollringServer: checked });
    };

    const handleTimezoneChange = (alwaysNull, data) => {
      SetServerData({ ...serverData, Timezone: data.value.header });
    };

    React.useEffect(() => {
       props.acquireToken(authSuccessCallback);
    }, []);

    const authSuccessCallback = (token) => {
       if (token) {
         SettingsService.GetVMData(token).then((response) => {
          if(response && response.status==200){
            response=response.data;
             setVMData(response);
             SettingsService.GetMasterInstanceServerData(token).then(
               (response) => {
                if(response && response.status==200){
                  response=response.data;
                   setRegions(response.Region);
                   setTimezones(response.Timezone);
                   setServerTypes(response.ServerTypes);
                 }
               }
             );
           }
           else if(response && response.status==401){            
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else{
            props.viewStatus.InformChildPageStatus(500);
          }
          SetLoaded(true);
         });          
       }
    };

    const ActionIconVM = (props) => {
    return (
        <td style={{ textAlign: "center" }}>
            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/>
            <Button styles={{ height: 'auto' }} iconOnly size="small" icon={<TrashCanIcon />} text onClick={() => {
              setDeleteServerObj(props.dataItem);
              setDeleteConfirmationDialog(true);
            }}/>
        </td>
    );
    }

    const removeData = () => {
       if (deleteServerObj != null) {
         props.acquireToken((token) =>
           authSuccessRemoveServerCallback(token, deleteServerObj.ServerId)
         );
       }
       setDeleteConfirmationDialog(false);
       setDeleteServerObj(null);
    };

    const handleAddEditClickOpen = (dataItem, isEdit) => {
      setVisible(true);
      if (isEdit === true) {
        SetServerData(dataItem);
        setEditMode(true);
      }
    };

    const handleAddEditClickClose = () => {
      setVisible(false);
      SetServerData(null);
      setEditMode(false);
      setIsValid(false);
    };

    const checkIsValid = () => {
      if (
        serverData?.DisplayFriendlyName !== undefined &&
        serverData?.DisplayFriendlyName?.length > 0 &&
        serverData?.IPAddress !== undefined &&
        serverData?.IPAddress?.length > 6 &&
        serverData?.MachineName !== undefined &&
        serverData?.MachineName?.length > 0 &&
        serverData?.Region !== undefined &&
        serverData?.Region?.length > 0 &&
        serverData?.ServerTypeName !== undefined &&
        serverData?.ServerTypeName?.length > 0 &&
        serverData?.RDPPort !== undefined  &&
        serverData?.RDPPort?.length > 0 &&
        serverData?.Timezone !== undefined  &&
        serverData?.Timezone?.length > 0
      ) {
        setIsValid(true);
      } else setIsValid(false);
    };

    const DetailComponent = (props1) => {
      const dataItem = props1.dataItem;
      return (
        <section>
          <p>
            IHubClientAddress: <strong>{dataItem.IHubClientAddress}</strong>
          </p>
          <p>
            Timezone: <strong>{dataItem.Timezone}</strong>
          </p>
          <p>
            Region: <strong>{dataItem.Region}</strong>
            </p>
            
        </section>
      );
    };

    const DetailComponentSm = (props1) => {
      const dataItem = props1.dataItem;
      return (
        <section>
          <p>
            IPAddress: <strong>{dataItem.IPAddress}</strong>
          </p>
          <p>
            ServerTypeName: <strong>{dataItem.ServerTypeName}</strong>
          </p>
          <p>
            MachineName: <strong>{dataItem.MachineName}</strong>
          </p>
          <p>
            IHubClientAddress: <strong>{dataItem.IHubClientAddress}</strong>
          </p>
          <p>
            Timezone: <strong>{dataItem.Timezone}</strong>
          </p>
          <p>
            Region: <strong>{dataItem.Region}</strong>
          </p>
          <p>
            RDPPort: <strong>{dataItem.RDPPort}</strong>
          </p>
          {
          props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) ?
          <Checkbox
            styles={{ marginRight: "10px" }}
            toggle
            labelPosition="start"
            label="TollServer:"
            checked={dataItem.IsTollringServer}
            onClick={(event, isChecked) => {
              dataItem.IsTollringServer = isChecked.checked;
              props.acquireToken((token) =>
                authSuccessUpdateTogglesCallback(token, dataItem)
              );
            }}
          />
          :
          props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.Configuration.Read" )) ?
          <Checkbox
            styles={{ marginRight: "10px" }}
            toggle
            labelPosition="start"
            label="TollServer:"
            checked={dataItem.IsTollringServer}
            disabled
          />
          : <></>
        }
          
          {/* <Checkbox
            styles={{ marginRight: "10px" }}
            toggle
            labelPosition="start"
            label="SyncEnabled:"
            checked={dataItem.SyncEnabledServer}
            onClick={(event, isChecked) => {
              dataItem.IsTollringServer = isChecked.checked;
              props.acquireToken((token) =>
                authSuccessUpdateTogglesCallback(token, dataItem)
              );
            }}
          /> */}
        </section>
      );
    };

    const CellCheckboxTollringServer = (props1) => {
      return  (
        <td style={{ textAlign: "center" }}>
          {
            props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) ?
            <Checkbox
            styles={{ marginRight: "10px" }}
            toggle
            checked={props1.dataItem.IsTollringServer}
            onClick={(event, isChecked) => {
              props1.dataItem.IsTollringServer = isChecked.checked;
              props.acquireToken((token) =>
                authSuccessUpdateTogglesCallback(token, props1.dataItem)
              );
            }}
          />
            :
            props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.Configuration.Read" )) ?
            <Checkbox
            styles={{ marginRight: "10px" }}
            toggle
            checked={props1.dataItem.IsTollringServer}
            disabled
          />:<></>
          }
          
        </td>
      );
    };

     const CellCheckboxAlertsDisabled = (props1) => {
      return (
        <td style={{ textAlign: "center" }}>
          {props.user?.Permissions?.some(
            (p) =>
              p == "Settings.ReadWrite" ||
              p == "Settings.Configuration.ReadWrite"
          ) ? (
            <Checkbox
              // styles={{ marginRight: "10px" }}
              toggle
              checked={props1.dataItem.AlertsDisabled}
              onClick={(event, isChecked) => {
                props1.dataItem.AlertsDisabled = isChecked.checked;
                props.acquireToken((token) =>
                  authSuccessUpdateTogglesCallback(token, props1.dataItem)
                );
              }}
            />
          ) : props.user?.Permissions?.some(
              (p) => p == "Settings.Read" || p == "Settings.Configuration.Read"
            ) ? (
            <Checkbox
              // styles={{ marginRight: "10px" }}
              toggle
              checked={props1.dataItem.AlertsDisabled}
              disabled
            />
          ) : (
            <></>
          )}
        </td>
      );
    };

    return isLoaded ? (
      <WindowContext.Consumer>
        {(context) => (
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <Card className="card-border bg-white h-100" fluid>
                <CardHeader className="mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="f-size-18">LIST OF SERVERS</div>
                    {props.user?.Permissions?.some(
                      (p) =>
                        p == "Settings.ReadWrite" ||
                        p == "Settings.Configuration.ReadWrite"
                    ) && (
                      <Button
                        icon={<AddIcon />}
                        iconPosition="before"
                        content="Add Server"
                        primary
                        title="Add Server"
                        onClick={handleAddEditClickOpen}
                        className="ml-auto"
                      />
                    )}
                  </div>

                  <div>
                    {visible && (
                      <Dialog
                        title={
                          editMode ? "Edit Server Details" : "Add New Server"
                        }
                        onClose={handleAddEditClickClose}
                      >
                        <Form onSubmit={submitHandler}>
                          <FormInput
                            label="Machine Name"
                            name="MachineName"
                            id="MachineName-inline"
                            fluid
                            required
                            value={serverData?.MachineName}
                            onChange={(value) =>
                              SetServerData({
                                ...serverData,
                                MachineName: value.target.value,
                              })
                            }
                          />
                          <FormInput
                            label="Friendly Name"
                            name="FriendlyName"
                            id="FriendlyName-inline"
                            fluid
                            required
                            value={serverData?.DisplayFriendlyName}
                            onChange={(value) =>
                              SetServerData({
                                ...serverData,
                                DisplayFriendlyName: value.target.value,
                              })
                            }
                          />

                          {/* <FormInput
                            label="HubClient Address"
                            name="HubClientAddress"
                            id="HubClientAddress-inline"
                            fluid
                            required
                            value={serverData?.IHubClientAddress}
                            onChange={(value) =>
                              SetServerData({
                                ...serverData,
                                IHubClientAddress: value.target.value,
                              })
                            }
                          /> */}
                          <FormInput
                            label="IP Address"
                            name="IP"
                            id="IP-inline"
                            fluid
                            minLength={6}
                            required
                            value={serverData?.IPAddress}
                            onChange={(value) =>
                              SetServerData({
                                ...serverData,
                                IPAddress: value.target.value,
                              })
                            }
                          />
                          <FormInput
                            label="RDPPort"
                            name="RDPPort"
                            id="RDPPort-inline"
                            fluid
                            required
                            maxLength={4}
                            value={serverData?.RDPPort}
                            onChange={(value) =>
                              SetServerData({
                                ...serverData,
                                RDPPort: value.target.value,
                              })
                            }
                          />
                          <FormDropdown
                            // label={{
                            //   content: `ServerTypeName`,
                            //   id: "ServerTypeName-inline",
                            // }}
                            items={serverTypes}
                            aria-labelledby={"ServerType-inline"}
                            //search={true}
                            placeholder="Choose Server Type"
                            required
                            autosize="true"
                            value={serverData?.ServerTypeName}
                            onChange={handleServerTypeChange}
                          />
                          <FormDropdown
                            // label={{
                            //   content: `Region`,
                            //   id: "Region-inline",
                            // }}
                            items={regions.map((obj) => {
                              return {
                                header: obj.RegionName,
                                value: obj.Id,
                                key: obj.Id,
                              };
                            })}
                            aria-labelledby={"Region-inline"}
                            placeholder="Choose Region"
                            required
                            autosize="true"
                            value={serverData?.Region}
                            onChange={handleRegionChange}
                          />
                          <FormDropdown
                            // label={{
                            //   content: `Timezone`,
                            //   id: "Timezone-inline",
                            // }}
                            items={timezones.map((obj) => {
                              return {
                                header: obj.TimezoneName,
                                value: obj.Id,
                                key: obj.Id,
                              };
                            })}
                            aria-labelledby={"Timezone-inline"}
                            search={true}
                            placeholder="Choose Timezone"
                            required
                            autosize="true"
                            value={serverData?.Timezone}
                            onChange={handleTimezoneChange}
                          />
                          <FormCheckbox
                            label="Tollring Server"
                            id="TollringServer"
                            value={serverData?.IsTollringServer}
                            checked={serverData?.IsTollringServer}
                            onChange={(e, { checked }) => {
                              handleTollringServerChange(checked);
                            }}
                          />
                          <div class="d-flex">
                            <FormCheckbox
                              label="Alerts Diabled"
                              id="AlertsDisabled"
                              value={serverData?.AlertsDisabled}
                              checked={serverData?.AlertsDisabled}
                              onChange={(e, { checked }) => {
                                handleTollringServerChange(checked);
                              }}
                            />
                            
                            <Tooltip
                              pointing
                              trigger={<InfoIcon />}
                              content="Infra alert will not be generated."
                            />
                          </div>
                          {/* <FormCheckbox label="SyncEnabled" id="SyncEnabled" /> */}

                          <DialogActionsBar>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              onClick={addEditSubmitClickHandler}
                              disabled={isValid === false}
                            >
                              {" "}
                              Submit
                            </button>
                            <button
                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                              onClick={handleAddEditClickClose}
                            >
                              {" "}
                              Close
                            </button>
                          </DialogActionsBar>
                        </Form>
                      </Dialog>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="tbl-search float-right text-right mb-2">
                    <Input
                      onChange={(e, { name, value }) => {
                        filterGridData(value);
                      }}
                      inverted
                      icon={<SearchIcon />}
                      placeholder="Search..."
                      className="border-gray"
                    />
                  </div>
                  <div className="grid-with-search">
                    <Grid
                      style={{
                        height: "100%",
                      }}
                      // pageable={true}
                      pageable={{
                        buttonCount: 3,
                      }}
                      sortable={true}
                      data={process(vmData, dataState)}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                      detail={
                        context.mediaCategory.sm
                          ? DetailComponentSm
                          : DetailComponent
                      }
                      expandField="expanded"
                      onExpandChange={expandChange}
                    >
                      <Column
                        field="DisplayFriendlyName"
                        title="Friendly Name"
                        cell={RenderTooltipCell}
                        width={140}
                      />
                      {!context.mediaCategory.sm && (
                        <Column field="IPAddress" title="IP" width={120}cell={RenderTooltipCell} />
                      )}
                      {!context.mediaCategory.sm && (
                        <Column
                          field="ServerTypeName"
                          title="Type"
                          cell={RenderTooltipCell}
                          width={140}
                        />
                      )}
                      {!context.mediaCategory.sm && (
                        <Column field="MachineName"cell={RenderTooltipCell} title="Name" width={140} />
                      )}
                      {!context.mediaCategory.sm && (
                        <Column
                          field="IsTollringServer"
                          cell={CellCheckboxTollringServer}
                          title="TollServer"
                          width={90}
                        />
                      )}
                      {!context.mediaCategory.sm && (
                        <Column
                          field="AlertsDisabled"
                          cell={CellCheckboxAlertsDisabled}
                          title="AlertsDisabled"
                          width={110}
                        />
                      )}
                      {!context.mediaCategory.sm && (
                        <Column field="RDPPort" title="RDP Port" width={100} />
                      )}
                      {/* {!context.mediaCategory.sm && (
                        <Column
                          field="SyncEnabledServer"
                          title="Sync"
                          width={70}
                          cell={CellCheckboxSyncEnabled}
                        />
                      )} */}
                      {props.user?.Permissions?.some(
                        (p) =>
                          p == "Settings.ReadWrite" ||
                          p == "Settings.Configuration.ReadWrite"
                      ) && (
                        <Column
                          field=""
                          title="Actions"
                          cell={ActionIconVM}
                          width={100}
                        />
                      )}
                    </Grid>
                  </div>
                </CardBody>
              </Card>
            </div>
            <FluentDialog
              cancelButton="Cancel"
              confirmButton="Confirm"
              onCancel={() => {
                setDeleteConfirmationDialog(false);
                setDeleteServerObj(null);
              }}
              onConfirm={() => removeData()}
              open={openDeleteConfirmationDialog}
              backdrop
              header="Action confirmation"
              headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => {
                  setDeleteConfirmationDialog(false);
                  setDeleteServerObj(null);
                },
              }}
              content={`Are you sure to delete Server: ${deleteServerObj?.DisplayFriendlyName}?`}
            />
          </div>
        )}
      </WindowContext.Consumer>
    ) : (
      <Loader />
    );
}

export { SettingsVM };