import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Loader, Button, Flex, Input, Tooltip, Card, CardHeader, CardBody, Pill } from '@fluentui/react-northstar';
import { ExcelColorIcon, InfoIcon } from '@fluentui/react-icons-northstar';
import RevenueService from "../../../services/revenueservice";
import '../microsoftbilling/microsoftbilling.scss';
import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';
import DateFilterDialog from '../customerbilling/dateFilterDialog';
import { RenderTooltipCell } from "../../../shared/appcomponents/TooltipWithCopy"

const PAGE_SIZE = 10;

const CustomerBillingDashboard = (props) => {
    const { acquireToken, viewStatus } = props;
    const [gridData, setGridData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [skip, setSkip] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [dataState, setDataState] = useState({
        sort: [{ field: "Product", dir: "asc" }] // Initialize with a default sort field such as 'Product'
    });

    /* const [isOpen, setIsOpen] = useState(false); */

    const fetchGridData = useCallback((token, filters = {}) => {
        setLoaded(false);
        // Debugging output
        console.log('Fetching grid data with filters:', filters);

        const filterPayload = {
            ...filters,
        };
        console.log(filterPayload);

        if (filters.excludeInactive) {
            filterPayload.Active = true; // This line ensures only active records are fetched
        } else {
            delete filterPayload.Active; // Remove or clear field if not filtering by active state
        }

        if (Object.keys(filterPayload).some(key => filterPayload[key] !== null)) {
            RevenueService.GetCustomersBillingGridDataFiltered(token, filterPayload)
                .then((response) => {
                    if (response && response.status === 200) {
                        setGridData(response.data); // Keep the entire data set for calculations
                        // Slicing only affects the paginated view in the grid
                        setFilteredData(response.data.slice(skip, skip + PAGE_SIZE));
                    } else {
                        viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    }
                    setLoaded(true);
                })
                .catch((error) => {
                    console.error('Error fetching Microsoft Billing data:', error);
                    setLoaded(true);
                });
        } else {
            RevenueService.GetCustomersBillingGridData(token)
                .then((response) => {
                    if (response && response.status === 200) {
                        setGridData(response.data);
                        setFilteredData(response.data.slice(0, PAGE_SIZE));
                        setLoaded(true);
                    } else {
                        viewStatus.InformChildPageStatus(response?.status || 500);
                    }
                    setLoaded(true);
                })
                .catch((error) => {
                    console.error('Error fetching Microsoft Billing data:', error);
                    setLoaded(true);
                });
        }
    }, [viewStatus, skip]);

    const handleSearch = (query) => {
        setSearchQuery(query.toLowerCase());
        const filtered = gridData.filter(item =>
            Object.keys(item).some(key =>
                String(item[key]).toLowerCase().includes(query.toLowerCase())
            )
        );
        setFilteredData(filtered.slice(skip, skip + PAGE_SIZE));
        setSkip(0); // Reset pagination after search
    };

    useEffect(() => {
        let sortedData = [...gridData];
        if (dataState.sort.length > 0) {
            const { field, dir } = dataState.sort[0];
            sortedData.sort(dynamicSort(field, dir));
        }
        setFilteredData(sortedData.slice(skip, skip + PAGE_SIZE));
    }, [gridData, dataState, skip]);


    const resetGridData = useCallback((token) => {
        fetchGridData(token);
    }, [fetchGridData]);

    useEffect(() => {
        acquireToken((token) => {
            fetchGridData(token, appliedFilters);
        });
    }, [acquireToken, appliedFilters, skip, fetchGridData]);

    useEffect(() => {
        setFilteredData(gridData.slice(skip, skip + PAGE_SIZE));
    }, [gridData, skip]);

    console.log('Enddate:', DateFilterDialog.endDate);
    console.log("StartDate",DateFilterDialog.startDate);

    const handleFilterUpdate = (filters) => {
        const filterPayload = {
            Product: filters.Product.length > 0 ? filters.Product.join(',') : null,
            Instance: filters.Instance.length > 0 ? filters.Instance.join(',') : null,
            Platform: filters.Platform.length > 0 ? filters.Platform.join(',') : null,
            Region: filters.Region.length > 0 ? filters.Region.join(',') : null,
            StartDate: filters.startDate || null,
            EndDate: filters.endDate || null,
            excludeInactive: filters.excludeInactive
        };

        if (filters.excludeInactive) {
            filterPayload.Active = true; // This line ensures only active records are fetched
        } else {
            delete filterPayload.Active; // Remove or clear field if not filtering by active state
        }
        setAppliedFilters(filterPayload);
        acquireToken((token) => {
            RevenueService.GetCustomersBillingGridDataFiltered(token, filterPayload)
                .then((response) => {
                    if (response.status === 200) {
                        setGridData(response.data);
                        setFilteredData(response.data.slice(0, PAGE_SIZE));
                        setSkip(0);
                    } else {
                        console.error('Error fetching filtered data:', response.statusText);
                    }
                })
                .catch((error) => {
                    console.error('Error in API call:', error);
                });
        });
    };

    const handleDownloadCsv = () => {
        console.log('Downloading with filters:', appliedFilters); // Debugging output
        setDownloading(true);
        acquireToken((token) => {
            RevenueService.DownloadCustomersBillingCsv(token, appliedFilters)
                .then((response) => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], { type: 'text/csv' });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Microsoft_Partner_Billing.csv');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        console.error('Error downloading CSV:', response.statusText);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setDownloading(false);
                });
        });
    };



    const handlePageChange = (event) => {
        const newSkip = event.page.skip;
        setSkip(newSkip);
        const newFilteredData = gridData.slice(newSkip, newSkip + PAGE_SIZE);
        setFilteredData(newFilteredData);
    };

    const filterDialog = DateFilterDialog({
        handleFilterUpdate,
        elegibleFilters: ['startDate', 'endDate','StartDate','EndDate',], // Include "Date"
        acquireToken,
        resetGridData
    });

    /* const RenderTooltipCell = (props) => {
        return (
            <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip
                    content={props.dataItem[props.field]}
                    trigger={<span>{props.dataItem[props.field]}</span>}
                />
            </td>
        );
    }; */

    const addedThisMonth = useMemo(() => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        return gridData.filter(item => {
            const itemDate = new Date(item.DateCreated);
            return itemDate.getMonth() === currentMonth && itemDate.getFullYear() === currentYear;
        }).length;
    }, [gridData]);

    const addedLastMonth = useMemo(() => {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return gridData.filter(item => {
            const itemDate = new Date(item.DateCreated);
            return itemDate.getMonth() === lastMonth.getMonth() && itemDate.getFullYear() === lastMonth.getFullYear();
        }).length;
    }, [gridData]);


    // Compute "Total Amount" (active customers only)
    const grossAmount = useMemo(() => {
        return gridData
            .filter(item => item.Active === true) // Only active customers
            .reduce((sum, item) => sum + (Number(item.Amount) || 0), 0)
            .toFixed(2);
    }, [gridData]);

    const grossAmountRemoved = useMemo(() => {
        return gridData
            .filter(item => item.Active === false) // Only removed customers
            .reduce((sum, item) => sum + (Number(item.Amount) || 0), 0)
            .toFixed(2);
    }, [gridData]);

    const totalCustomers = useMemo(() => {
        return gridData.filter(item => item.Active === true).length;
    }, [gridData]);

    const totalCustomersRemoved = useMemo(() => {
        return gridData.filter(item => item.Active === false).length;
    }, [gridData]);

    const removedThisMonth = useMemo(() => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        return gridData.filter(item => {
            const deletedDate = item.PackageAddedDate ? new Date(item.PackageAddedDate) : null;
            return (
                item.Active === false && // Active == false for removed
                deletedDate &&
                deletedDate.getMonth() === currentMonth &&
                deletedDate.getFullYear() === currentYear
            );
        }).length;
    }, [gridData]);

    const removedLastMonth = useMemo(() => {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);

        return gridData.filter(item => {
            const deletedDate = item.PackageAddedDate ? new Date(item.PackageAddedDate) : null;
            return (
                item.Active === false && // Active == false for removed
                deletedDate &&
                deletedDate.getMonth() === lastMonth.getMonth() &&
                deletedDate.getFullYear() === lastMonth.getFullYear()
            );
        }).length;
    }, [gridData]);

    const addedDetails = {
        thisMonth: {
            added: addedThisMonth,
            details: `Total added this month: ${addedThisMonth}`
        },
        lastMonth: {
            added: addedLastMonth,
            details: `Total added last month: ${addedLastMonth}`
        },
        grossAmountDetails: {
            amount: grossAmount.toLocaleString(),
            details: `Total amount for current filter: ${grossAmount.toLocaleString()}`
        }
    };

    const getPercentageChange = (value) => {
        // Dummy calculation; replace with real logic based on previous data or a specific baseline
        return ((value / totalCustomers) * 100).toFixed(1);
    };

    function dynamicSort(property, order = 'asc') {
        let sortOrder = 1;
        if (order === 'desc') {
            sortOrder = -1;
        }
        return function (a, b) {
            let valueA = a[property];
            let valueB = b[property];

            if (valueA == null) valueA = '';
            if (valueB == null) valueB = '';

            if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
                valueA = new Date(valueA);
                valueB = new Date(valueB);
            }

            if (typeof valueA === 'string') valueA = valueA.toLowerCase();
            if (typeof valueB === 'string') valueB = valueB.toLowerCase();

            if (valueA < valueB) return -1 * sortOrder;
            if (valueA > valueB) return 1 * sortOrder;
            return 0;
        };
    };
    function formatDate(dateString) {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: '2-digit' };
        return date.toLocaleDateString(undefined, options);
    }

    const isEndDateToday = (endDate) => {
        if (!endDate) return false; // No end date provided
        const selectedEndDate = new Date(endDate);
        const currentDate = new Date();
        return (
            selectedEndDate.getFullYear() === currentDate.getFullYear() &&
            selectedEndDate.getMonth() === currentDate.getMonth() &&
            selectedEndDate.getDate() === currentDate.getDate()
        );
    };

    return (
        <div className="microsoft-billing-dashboard">

            <Flex space="between" className="filter-pill-container">
                {filterDialog}
                <Flex>
                    <div>
                        <Flex>
                            <Input
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            <div className='icnTrk ml-2 download-icon'>
                                {downloading ? (
                                    <Loader size="smallest" />
                                ) : (
                                    <Button
                                        title="Download Excel"
                                        icon={<ExcelColorIcon outline rotate={0} size="large" />}
                                        iconOnly
                                        onClick={handleDownloadCsv}
                                    />
                                )}
                            </div>
                        </Flex>
                    </div>
                </Flex>


            </Flex>
            {/* <div className='row d-flex align-items-stretch top-kpi'>
                {(Object.keys(appliedFilters).length === 0 || isEndDateToday(appliedFilters.EndDate)) ? (
                    <>
                        <div className='col-12 col-md-3 mb-4'>
                            <Card className='card-border bg-light-purple rev-chart'>
                                <CardHeader>
                                    <h6>Total Active Customers</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{totalCustomers}</h1>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-12 col-md-3 mb-4'>
                            <Card className='card-border bg-light-purple rev-chart'>
                                <CardHeader>
                                    <h6>Total Amount</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{`$${grossAmount}`}</h1>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-12 col-md-3 mb-4'>
                            <Card className='card-border bg-light-purple rev-chart'>
                                <CardHeader>
                                    <h6>Added This Month</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{addedDetails.thisMonth.added}</h1>
                                    <Pill className='healthy'>+{getPercentageChange(addedDetails.thisMonth.added)}%<ArrowUpIcon /></Pill>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-12 col-md-3 mb-4'>
                            <Card className='card-border bg-light-purple rev-chart'>
                                <CardHeader>
                                    <h6>Added Last Month</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{addedDetails.lastMonth.added}</h1>
                                    <Pill className='healthy'>+{getPercentageChange(addedDetails.lastMonth.added)}%<ArrowUpIcon /></Pill>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-12 col-md-3 mb-4">
                            <Card className="card-border bg-light-purple rev-chart">
                                <CardHeader>
                                    <h6>Total Customers Removed</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{totalCustomersRemoved}</h1>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-12 col-md-3 mb-4'>
                            <Card className='card-border bg-light-purple rev-chart'>
                                <CardHeader>
                                    <h6>Total Amount (Removed Customers)</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{`$${grossAmountRemoved}`}</h1>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-12 col-md-3 mb-4">
                            <Card className="card-border bg-light-purple rev-chart">
                                <CardHeader>
                                    <h6>Removed This Month</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{removedThisMonth}</h1>
                                    <Pill className="critical">
                                        -{getPercentageChange(removedThisMonth)}%
                                        <ArrowUpIcon rotate="180" />
                                    </Pill>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-12 col-md-3 mb-4">
                            <Card className="card-border bg-light-purple rev-chart">
                                <CardHeader>
                                    <h6>Removed Last Month</h6>
                                </CardHeader>
                                <CardBody>
                                    <h1>{removedLastMonth}</h1>
                                    <Pill className="critical">
                                        -{getPercentageChange(removedLastMonth)}%
                                        <ArrowUpIcon rotate="180" />
                                    </Pill>
                                </CardBody>
                            </Card>
                        </div>
                    </>
                ) : null}
            </div> */}
            <div style={{ width: '100%', overflowX: 'auto', paddingBottom: '1rem' }}>

                <Grid
                    data={filteredData}
                    skip={skip}
                    pageable={true}
                    pageSize={PAGE_SIZE}
                    total={gridData.length}
                    onPageChange={handlePageChange}
                    resizable
                    onDataStateChange={(e) => setDataState(e.dataState)}
                    sortable={true}
                    sort={dataState.sort}

                >
                    <Column
                        field="Product"
                        title="Product"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="Platform"
                        title="Platform"
                        width="150px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Region"
                        title="Region"
                        width="80px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="ProvisioningPartnerName"
                        title="Provisioning Partner Name"
                        width="220px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="CustomerName"
                        title="Customer Name"
                        width="160px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="InHouse"
                        title="In House"
                        width="160px"
                        cell={RenderTooltipCell}
                    />
                    
                    <Column
                        field="DateCreated"
                        title="Date Created"
                        width="170px"
                        sortable={true}
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                    <Column
                        field="CXDeactivationDate"
                        title="CX Deactivation Date"
                        width="170px"
                        sortable={true}
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                    <Column
                        field="RXDeactivationDate"
                        title="RX Deactivation Date"
                        width="170px"
                        sortable={true}
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                    <Column
                        field="DateDeactivated"
                        title={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Date Deactivated
                                <Tooltip
                                    pointing
                                    trigger={<InfoIcon style={{ marginLeft: '5px', cursor: 'pointer' }} />} // Adjust color
                                    content="Date on which subscription was cancelled"
                                />
                            </span>
                        }
                        sortable={true}
                        width="170px"
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                    <Column
                        field="Status"
                        title="Status"
                        width="120px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="CustomerEmail"
                        title="Customer Email"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="CustomerAddress"
                        title="Customer Address"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="Country"
                        title="Country"
                        width="120px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="TrialEndDate"
                        title="Trial End Date"
                        sortable={true}
                        width="170px"
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                    <Column
                        field="FirstActiveBillingDate"
                        title="First Active Billing Date"
                        sortable={true}
                        width="180px"
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                    <Column
                        field="TotalCXQuantity"
                        title="Total CX Licenses"
                        width="140px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="TotalRXQuantity"
                        title="Total RX Licenses"
                        width="140px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="A365RXE30Quantity"
                        title="A365-RXE30 Licenses"
                        width="180px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="A365RXE90Quantity"
                        title="A365-RXE90 Licenses"
                        width="180px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="A365RXE1Quantity"
                        title="A365-RXE1 Licenses"
                        width="160px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="A365RXE7Quantity"
                        title="A365-RXE7 Licenses"
                        width="160px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="A365RXA1Quantity"
                        title="A365-RXA1 Licenses"
                        width="160px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="A365RXU7Quantity"
                        title="A365-RXU7 Licenses"
                        width="165px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    {/* <Column
                        field="Company"
                        title="Customer Name"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="PackageCode"
                        title="Package Code"
                        width="120px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="PackageName"
                        title="License Description"
                        width="180px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="Quantity"
                        title="Total Licenses"
                        width="120px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="IsCustomerProvisionedSuccessfully"
                        title="Customer Provisioned"
                        width="180px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field] ? "Yes" : "No"}</td>}
                    /> 
                    
                    <Column
                        field="BillStartDate"
                        title="Bill Start Date"
                        sortable={true}
                        width="170px"
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />*/}
                    {/* <Column
                        field="PackageId"
                        title="Package Id"
                        width="100px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    /> */}
                    {/* <Column
                        field="PackageAddedDate"
                        title="Package Added Date"
                        sortable={true}
                        width="170px"
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />*/}
                    {/* <Column
                        field="SubscriptionStatusId"
                        title="Subscription Status Id"
                        width="180px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    /> */}
                    {/* <Column
                        field="Active"
                        title="Active"
                        width="80px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field] ? "Yes" : "No"}</td>}
                    /> */}
                    {/* <Column
                        field="ServiceProviderId"
                        title="Service Provider ID"
                        width="150px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    /> */}
                    {/* <Column
                        field="CreatedBy"
                        title="Created By"
                        width="150px"
                        cell={RenderTooltipCell}
                    /> */}
                    {/* <Column
                        field="CustomerId"
                        title="Customer Id"
                        width="110px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    /> */}
                    {/*
                    <Column
                        field="DateDeactivated"
                        title="Date Deactivated"
                        sortable={true}
                        width="170px"
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                     <Column
                        field="DateProvisioned"
                        title="Date Provisioned"
                        width="150px"
                        cell={RenderTooltipCell}
                    /> */}


                </Grid>
            </div>
            {!loaded && (
                <div className="loading-indicator">
                    <Loader type="infinite-spinner" />
                    Loading data...
                </div>
            )}
        </div>
    );
};

export default CustomerBillingDashboard;
