import React, {useState} from 'react'
import { Card, Flex, Text, Button, Tooltip, Toolbar } from '@fluentui/react-northstar'
import { BroadcastIcon, BellIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useSearchParams, useOutletContext } from "react-router-dom";
import { setRouteInLocalStorage } from '../../../shared/contexts/Context';

const DbDashboard = (props) => {
    const [viewArea, setViewArea] = React.useState("");
    const [searchParams] = useSearchParams();
    let navigate = useNavigate(); 
    const routeChange = (path, varea) =>{
        setViewArea(varea);
        setRouteInLocalStorage('contentUrl_dashboards', path);
        navigate(path);
    }
    React.useEffect(() => {
        let varea = searchParams.get('type');
        if(varea){
            setViewArea(varea);
        }
        else{
            if(window.location.pathname.includes('history')){
                setViewArea('history');
            }
            else{
                setViewArea("detail");
            }
        }
    }, []);
    return(
        <>
        <div className='dashboard-top'>
            <div className='row'>
                <div className='col-sm-12 col-xs-12 mt-2 mb-3'>
                    <Card className='card card-infra' elevated >
                        {/* <Card.Header>
                            <Flex gap="gap.small" space="between" className='w-100'>
                                <Text content="Infra Alarms" />
                            </Flex>
                        </Card.Header> */}
                        <Card.Body>
                            <Toolbar
                            items={[
                                {
                                  key: 'custom-text',
                                  content: <Text content="Infra Alarms" />,
                                  kind: 'custom',
                                  className: 'heading',
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-focusable-text',
                                  content: <Button icon={10} content="Critical" iconPosition="after"  onClick={() => routeChange('/dashboards/infra/alarms?type=critical', 'critical')} />,
                                  focusable: true,
                                  kind: 'custom',
                                  active: true,
                                  className: 'btn critical',
                                  fitted: 'horizontally',
                                },
                                {
                                  key: 'custom-button',
                                  kind: 'custom',
                                  content: <Button icon={7} content="Warning" iconPosition="after"  onClick={() => routeChange('/dashboards/infra/alarms?type=warning', 'warning')} />,
                                  fitted: 'horizontally',
                                  className: 'btn warning',
                                },
                                {
                                  key: 'custom-infra-detail',
                                  kind: 'custom',
                                  content:  <Tooltip pointing iconOnly trigger={<Button className={`${viewArea == "overview" ? 'selected' : ''}`} icon={<BroadcastIcon />} text onClick={() => routeChange('/dashboards/infra/detail', 'detail')} />} content="Infra Detail" />,
                                  fitted: 'horizontally',
                                  className: 'card-actions',
                                },
                                {
                                  key: 'custom-infra-history',
                                  kind: 'custom',
                                  content: <Tooltip pointing iconOnly trigger={<Button className={`${viewArea == "history" ? 'selected' : ''}`} icon={<BellIcon />} text onClick={() => routeChange('/dashboards/infra/history', 'history')} />} content="Historical Alarms" />,
                                  fitted: 'horizontally',
                                  className: 'card-actions',
                                },
                              ]}
                            >
                            </Toolbar>
                            <BroadcastIcon outline rotate={0} size="largest" />
                            {/* <div className='row mt-3'>
                                <div className='col-sm-4 pr-1'>
                                    <div className={`d-flex btn critical ${viewArea == "critical" ? 'selected' : ''}`}>
                                        <Button icon={10} content="Critical Servers" iconPosition="after"  onClick={() => routeChange('/dashboards/infra/alarms?type=critical', 'critical')} />
                                    </div>
                                </div>
                                <div className='col-sm-4 pr-1 pl-1'>
                                    <div className={`d-flex btn warning ${viewArea == "warning" ? 'selected' : ''}`}>
                                        <Button icon={7} content="Warning Servers" iconPosition="after"  onClick={() => routeChange('/dashboards/infra/alarms?type=warning', 'warning')} />
                                    </div>
                                </div>
                                <div className='col-sm-4 pl-1'>
                                    <div className={`d-flex btn healthy ${viewArea == "healthy" ? 'selected' : ''}`}>
                                        <Button icon={83} content="Healthy Servers" iconPosition="after"  onClick={() => routeChange('/dashboards/infra/alarms?type=healthy', 'healthy')} />
                                    </div>
                                </div>
                            </div> */}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
        <div className='dashboard-details mb-3'>
            <div className='row'>
                <div className='col-sm-12'>
                    <Outlet />
                </div>
            </div>
        </div>
        </>
    )
}

export const DbMonitoring = (props) => {
    
    return(
        <Outlet />
    )
}

export default DbDashboard;