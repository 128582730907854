import React, { useRef } from 'react';
import { Tooltip } from '@fluentui/react-northstar';

export const RenderTooltipCell = (props) => {
    const content = props.dataItem[props.field];
    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const [copyMessage, setCopyMessage] = React.useState(''); // Control copy message
    const tooltipTimeoutRef = useRef(null);

    const handleCopy = () => {
        navigator.clipboard.writeText(content).then(() => {
            setCopyMessage('Copied!'); // Set the message
            setTimeout(() => {
                setCopyMessage('Copy'); // Reset button text back after 2 seconds
            }, 2000);
        });
    };

    const handleMouseEnter = () => {
        clearTimeout(tooltipTimeoutRef.current);
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        tooltipTimeoutRef.current = setTimeout(() => {
            setTooltipVisible(false);
        }, 2000);
    };

    const handleTooltipMouseEnter = () => {
        clearTimeout(tooltipTimeoutRef.current);
    };

    const handleTooltipMouseLeave = () => {
        tooltipTimeoutRef.current = setTimeout(() => {
            setTooltipVisible(false);
        }, 2000);
    };

    return (
        <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
            <Tooltip
                content={
                    <div
                        style={{ padding: '5px', maxWidth: '200px', wordWrap: 'break-word' }}
                        onMouseEnter={handleTooltipMouseEnter}
                        onMouseLeave={handleTooltipMouseLeave}
                    >
                        {content}
                        <div>
                            <button onClick={handleCopy} style={{ marginTop: '5px' }}>
                                {copyMessage || 'Copy'} {/* Change button text based on copy status */}
                            </button>
                        </div>
                    </div>
                }
                trigger={
                    <span
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {content}
                    </span>
                }
                visible={tooltipVisible}
            />
        </td>
    );
};