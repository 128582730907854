import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Button, Input, Form, FormDropdown, Loader, EditIcon, TrashCanIcon, Alert, Dialog as FluentDialog } from '@fluentui/react-northstar';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { AddIcon, SearchIcon, CloseIcon } from '@fluentui/react-icons-northstar';
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { Pill, PillGroup } from "@fluentui/react-northstar";
import { WindowContext } from "../../shared/contexts/Context";
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
const InstanceServers = (props) => {
  const [instanceServerMapping, setInstanceServerMapping] = React.useState(null);
  const [instanceNames, setInstanceNames] = React.useState(null);
  const [serverNames, setServerNames] = React.useState(null);
  const [isLoaded, SetLoaded] = React.useState(false);
  const [instanceServerData, SetInstanceServerData] = useState(null);
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [isValid, setIsValid] = useState(false);
  const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deleteInstanceServerObj, setDeleteInstanceServerObj] = useState(null);

  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "InstanceName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [
        { field: "InstanceName", operator: "contains", value: value },
        { field: "MachineNameList", operator: "contains", value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  useEffect(() => checkIsValid(), [instanceServerData]);


  const addEditSubmitClickHandler = () => {
    props.acquireToken(authSuccessAddEditServerCallback);
    setVisible(false);
    SetInstanceServerData(null);
    setEditMode(false);
    setIsValid(false);
  };

  const authSuccessAddEditServerCallback = (token) => {
    if (token) {
      if (editMode == true) {
        SettingsService.UpdateInstanceServerMapping(
          token,
          instanceServerData
        ).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);  
          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      } else {
        SettingsService.AddInstanceServerMapping(
          instanceServerData,
          token
        ).then((response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }  else if(response && response.status==401){
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);  
          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        });
      }
    }
  };

  const authSuccessRemoveInstanceServerCallback = (token, InstanceName) => {
    if (token) {
      let _params = {
        InstanceName: InstanceName,
      };
      SettingsService.RemoveInstanceServerMapping(token, _params).then(
        (response) => {
          if(response && response.status==200){
            response=response.data;
            props.acquireToken(authSuccessCallback);
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
          }  else if(response && response.status==401){
            debugger;
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);  
          }
          else {
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
          }
        }
      );
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const handleInstanceNameChange = (alwaysNull, data) => {
    SetInstanceServerData({ ...instanceServerData, InstanceName: data.value });
  };

  const handleServerNameChange = (data) => {
    SetInstanceServerData({
      ...instanceServerData,
      MachineNameList: data.value,
    });
  };

  React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const expandChange = (event) => {
    let newData = instanceServerMapping.map((item) => {
      if (item.InstanceName === event.dataItem.InstanceName) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setInstanceServerMapping(newData);
  };

  const authSuccessCallback = (token) => {
    if (token) {
            // console.log(token);
      SettingsService.GetInstanceServerMapping(token).then((response) => {
        if(response && response.status==200){
          response=response.data;
          setInstanceServerMapping(response);
          SettingsService.GetMasterInstanceServerData(token).then(
            (response) => {
              if(response && response.status==200){
                response=response.data;
                setInstanceNames(response.InstanceNames.map(item => item.InstanceName));
                setServerNames(response.ServerNames.map(item => item.MachinName));
              }
            }
          );
        }
        else if(response && response.status==401){
          props.viewStatus.InformChildPageStatus(response.status);
        }
        else {
          props.viewStatus.InformChildPageStatus(500);
        }        
        SetLoaded(true);
      });
    }
  };

  const ActionIconIS = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<EditIcon />}
          text
          onClick={() => handleAddEditClickOpen(props.dataItem, true)}
        />
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            setDeleteInstanceServerObj(props.dataItem);
            setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

  const CellGridPill = (props) => {
    return (
      <td>
        <PillGroup
          className='flex-wrap'
          aria-label={`server-list-${props.dataItem?.InstanceName.replaceAll(
            " ",
            "_"
          )}`}
        >
          {props.dataItem?.MachineNameList?.length > 4 ? (
            <>
              <Pill
                size="small"
                key={props.dataItem?.MachineNameList[0].ServerId}
              >
                {props.dataItem?.MachineNameList[0]}
              </Pill>
              <Pill
                size="small"
                key={props.dataItem?.MachineNameList[1].ServerId}
              >
                {props.dataItem?.MachineNameList[1]}
              </Pill>
              <Pill
                size="small"
                key={props.dataItem?.MachineNameList[2].ServerId}
              >
                {props.dataItem?.MachineNameList[2]}
              </Pill>
              <Pill
                size="small"
                key={props.dataItem?.MachineNameList[3].ServerId}
              >
                {props.dataItem?.MachineNameList[3]}
              </Pill>
              <Pill size="small">{`+ ${props.dataItem?.MachineNameList?.length - 4
                } more servers`}</Pill>
            </>
          ) : (
            props.dataItem?.MachineNameList?.map((s) => (
              <Pill size="small">{s}</Pill>
            ))
          )}
        </PillGroup>
      </td>
    );
  };

  const removeData = () => {
    if (deleteInstanceServerObj != null) {
      // console.log(deleteInstanceServerObj);
      props.acquireToken((token) =>
        authSuccessRemoveInstanceServerCallback(token, deleteInstanceServerObj.InstanceName)
      );
    }
    setDeleteConfirmationDialog(false);
    setDeleteInstanceServerObj(null);
  };

  const handleAddEditClickOpen = (dataItem, isEdit) => {
    setVisible(true);
    if (isEdit === true) {
      SetInstanceServerData(dataItem);
      setEditMode(true);
      checkIsValid();
    }
  };

  const handleAddEditClickClose = () => {
    setVisible(false);
    SetInstanceServerData(null);
    setEditMode(false);
    setIsValid(false);
  };

  const checkIsValid = () => {
    if (
      instanceServerData?.InstanceName !== undefined &&
      instanceServerData?.InstanceName?.length > 0 &&
      instanceServerData?.MachineNameList !== undefined &&
      instanceServerData?.MachineNameList?.length > 0
    ) {
      setIsValid(true);
    } else setIsValid(false);
  };

  const DetailComponentSm = (props1) => {
    return <CellGridPill {...props1} />
  };

  return isLoaded ? (
    <WindowContext.Consumer>
      {(context) => (
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <Card className="card-border bg-white h-100" fluid>
              <CardHeader className="mb-2">
                <div className="d-flex justify-content-between">
                  <div className="f-size-18">INSTANCE SERVER MAPPINGS</div>
                  
								 {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&

                  <Button
                    icon={<AddIcon />}
                    iconPosition="before"
                    content="Add Mapping"
                    primary
                    title="Add New Instance Server Mapping"
                    onClick={handleAddEditClickOpen} className="ml-auto"
                  />}
                </div>
                
                <div >
                  {visible && (
                    <Dialog
                      title={
                        editMode
                          ? "Edit Instance Server Mapping"
                          : "Add Instance Server Mapping"
                      }
                      width="600px"
                      onClose={handleAddEditClickClose}
                      id="add-edit-dialog"
                    >
                      <Form onSubmit={submitHandler}>
                        {!editMode && (
                          <FormDropdown
                            label={{
                              content: `Instance Name`,
                              id: "Instance Name-inline",
                            }}
                            items={instanceNames}
                            aria-labelledby={"Instance Name-inline"}
                            placeholder="Choose Instance Name"
                            required
                            autosize="true"
                            value={instanceServerData?.InstanceName}
                            onChange={handleInstanceNameChange}
                          />
                        )}
                        {editMode && (
                          <div>
                            Instance Name: {instanceServerData?.InstanceName}
                          </div>
                        )}

                        <div>
                          <div>Server Name:</div>
                          <MultiSelect
                            placeholder="Choose Server Name"
                            data={serverNames}
                            onChange={handleServerNameChange}
                            value={instanceServerData?.MachineNameList}
                          />
                        </div>
                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={addEditSubmitClickHandler}
                            disabled={isValid === false}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddEditClickClose}
                          >
                            {" "}
                            Close
                          </button>
                        </DialogActionsBar>
                      </Form>
                    </Dialog>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tbl-search float-right text-right mb-2">
                  <Input
                    onChange={(e, { name, value }) => {
                      filterGridData(value);
                    }}
                    inverted
                    icon={<SearchIcon />}
                    placeholder="Search..."
                    className="border-gray"
                  />
                </div>
                <div className='grid-with-search'>
                  <Grid
                    style={{
                      height: "100%",
                    }}
                    // pageable={true}
                    pageable={{
                      buttonCount: 3
                    }}
                    sortable={true}
                    data={process(instanceServerMapping, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                      setDataState(e.dataState);
                    }}
                    detail={context.mediaCategory.sm ? DetailComponentSm : null}
                    expandField="expanded"
                    onExpandChange={expandChange}
                  >
                    <Column field="InstanceName" title="Instance Name" filterable={false} width={200} />
                    {!context.mediaCategory.sm && (
                      <Column field="MachineNameList" title="Server Name" filterable={false} cell={CellGridPill} width={500} />
                    )}
                   
								 {props.user?.Permissions?.some((p) => (p == "Settings.ReadWrite" || p == "Settings.Configuration.ReadWrite" )) &&

                    <Column field="" title="Actions" cell={ActionIconIS} filterable={false} width={100}
                    />}
                  </Grid>
                </div>
              </CardBody>
            </Card>
          </div>
          <FluentDialog
            cancelButton="Cancel"
            confirmButton="Confirm"
            onCancel={() => {
              setDeleteConfirmationDialog(false);
              setDeleteInstanceServerObj(null);
            }}
            onConfirm={() => removeData()}
            open={openDeleteConfirmationDialog}
            backdrop
            header="Action confirmation"
            headerAction={{
              icon: <CloseIcon />,
              title: "Close",
              onClick: () => {
                setDeleteConfirmationDialog(false);
                setDeleteInstanceServerObj(null);
              },
            }}
            content={
              "Are you sure you want to delete mapping between " +
              deleteInstanceServerObj?.InstanceName +
              " and " +
              deleteInstanceServerObj?.MachineNameList?.join(", ") +
              "?"
            }
          />
        </div>
      )}
    </WindowContext.Consumer>
  ) : (
    <Loader />
  );
};

export { InstanceServers };
