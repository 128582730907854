import React from 'react'
import { Card, CardHeader, Tooltip, CardBody, Loader, Flex } from '@fluentui/react-northstar';
import { Grid as KendoGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridGlobalSearch from '../../../../shared/appcomponents/data-grid-search';
import { CellNumberedStringList, CellAccessIcon, CellHealthStatus, CellServerGraphLabel, CellDateTime, CellServiceName, CellInstanceOverviewLabel, CellOpenAlerts, CellColTitle, CellTenantedAlerts } from '../../../../shared/appcomponents/custom-grid-cells';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MonitoringService from '../../../../services/monitoringservice';
import { INCIDENTLEVELS, OPERATIONSTATUS } from '../../../../shared/appcomponents/enums';
import CustomQueryFilter from '../../../../shared/appcomponents/custom-query-filter';
import { process } from "@progress/kendo-data-query";
import { getRouteFromLocalStorage, WindowContext } from "../../../../shared/contexts/Context";
import { RenderTooltipCell } from "../../../../shared/appcomponents/TooltipWithCopy"

const ServiceDetail = (props) => {
  const { id, type } = useParams();
  const [details, setDetails] = React.useState(null);
  const [detailTable, setDetailTable] = React.useState(null);
  const [serviceState, setserviceState] = React.useState(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  }
  const [dataState, setDataState] = React.useState({
    sort: [
      {
        field: "ServiceName",
        dir: "asc",
      },
    ],
    take: 10,
    skip: 0,
  });

  const updateDetailData = (response) => {
    setDetails(response);
    response.Table.forEach((t) => {

    });
    setDetailTable(response.Table);
  }

  React.useEffect(() => {
    props.acquireToken((token) => {
      setLoaded(false);
      let _alarmType = searchParams.get('state');
      let _state = INCIDENTLEVELS[_alarmType];
      if (_state == null) {
        _state = -1;
      }
      setserviceState(_state);
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: _state,
        appliedFilters: type
          ? id
            ? [`InstanceId IN (${id})`, `ServiceTypeId IN (${type})`]
            : []
          : id
            ? [`InstanceId IN (${id})`]
            : [],
      };
      if (token) {
        MonitoringService.GetServiceMonitoringDetails(token, _params).then((response) => {
          if (response && response.status == 200) {
            response = response.data;
            updateDetailData(response);
          }
          else if (response && response.status == 401) {
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }

          setLoaded(true);
        });
      }
    });
  }, [id, type]);

  React.useEffect(() => {
    let _alarmType = searchParams.get('state');
    let _state = INCIDENTLEVELS[_alarmType];
    if (_state == null) {
      _state = -1;
    }
    setserviceState(_state);
  }, [searchParams]);

  const handleFilterUpdate = (appliedFilters) => {
    setLoaded(false);
    setDetails(null);
    setDetailTable(null);
    props.acquireToken((token) => {
      let _params = {
        instanceId: id ? id : -1,
        incidentLevelId: serviceState,
        appliedFilters: appliedFilters
      }
      if (token) {
        MonitoringService.GetServiceMonitoringDetails(token, _params).then((response) => {
          if (response && response.status == 200) {
            response = response.data;
            updateDetailData(response);
          }
          else if (response && response.status == 401) {
            props.viewStatus.InformChildPageStatus(response.status);
          }
          else {
            props.viewStatus.InformChildPageStatus(500);
          }

          setLoaded(true);
        });
      }
    })
  }

  const filterGridData = (value) => {
    let filter = {
      logic: 'or',
      filters: [
        { field: 'ServiceName', operator: 'contains', value: value },
        { field: 'ServiceDisplayName', operator: 'contains', value: value },
        { field: 'DCName', operator: 'contains', value: value },
        { field: 'InstanceName', operator: 'contains', value: value },
        { field: 'Platform', operator: 'contains', value: value },
      ],
    };
    setDataState({ ...dataState, filter: filter });
  };

  const expandChange = (event) => {
    let newData = detailTable.map((item) => {
      if (item.ServiceId === event.dataItem.ServiceId) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setDetailTable(newData);
  };
  
  const CellOpenAlertsCol = (prop) => {
    return (

      <CellOpenAlerts {...prop} acquireToken={props.acquireToken} />

    );
  }

  const CellTenantedAlertsCol = (prop) => {
    return (

      <CellTenantedAlerts {...prop} acquireToken={props.acquireToken} />

    );
  }



  return (
    <WindowContext.Consumer>
      {(context) => (
        <div className='fluid w-100'>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <Card className='card-border bg-white card-hov pt-2 pb-2' fluid ghost elevated>
                <CardHeader className='mb-2'>
                  <div className='d-flex'>
                    <div className='f-size-20'>Service Detail</div>
                  </div>
                </CardHeader>
                <div className='row'>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/service/detail${id ? '/' + id : ''}${type ? '/' + type : ''}`)}
                      className={`card-border bg-white br-0 pt-1 p-0 card-action ${serviceState == -1 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center'>
                        <div className='f-size-15'>Total Services</div>
                        <div className='f-size-26'>{details ? details.Total : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/service/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=healthy`)}
                      className={`card-border bg-success br-0 pt-1 p-0 card-action ${serviceState == 0 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Healthy</div>
                        <div className='f-size-26'>{details ? details.Healthy : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/service/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=warning`)}
                      className={`card-border bg-warning br-0 pt-1 p-0 card-action ${serviceState == 1 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Warning</div>
                        <div className='f-size-26'>{details ? details.Warning : '-'}</div>
                      </div>
                    </Card>
                  </div>
                  <div className='col-md-3 mb-3'>
                    <Card
                      onClick={() => routeChange(`/dashboards/monitoring/service/detail${id ? '/' + id : ''}${type ? '/' + type : ''}?state=critical`)}
                      className={`card-border bg-danger br-0 pt-1 p-0 card-action ${serviceState == 2 ? 'selected' : ''}`} fluid ghost elevated>
                      <div className='w-100 text-center text-white'>
                        <div className='f-size-15'>Critical - Heartbeat</div>
                        <div className='f-size-26'>{details ? details.Critical : '-'}</div>
                      </div>
                    </Card>
                  </div>
                </div>
                <CardBody>
                  <Flex vAlign='center' fluid className='w-100 mb-2 top-flex flex-sm-column'>
                    <CustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
                      defaultAppliedFilter={
                        type
                          ? [
                            { filter: "Instance", operator: "==", value: id },
                            {
                              filter: "ServiceType",
                              operator: "==",
                              value: type.split(",").map((i) => Number(i)),
                            },
                          ]
                          : { filter: 'Instance', operator: '==', value: id }
                      }
                      elegibleFilters={['Instance', 'Platform', 'ServiceType']} />
                    <Flex.Item push>
                      <div className='tbl-search float-right mt-sm-2 ml-sm-0 ml-md-auto ml-0'>
                        <GridGlobalSearch setSearchInput={(input) => filterGridData(input)} />
                      </div>
                    </Flex.Item>
                  </Flex>
                  <div className='w-100'>
                    {
                      isLoaded ?
                        (
                          <KendoGrid
                            style={{
                              height: "456px",
                            }}
                            // pageable={true}
                            pageable={{
                              buttonCount: 3
                            }}
                            sortable={true}
                            data={process(detailTable.filter(tr => {
                              if (serviceState == -1) { return true }
                              else if (serviceState != -1 && tr.Health == serviceState) { return true }
                              return false;
                            }), dataState)}
                            {...dataState}
                            onDataStateChange={(e) => {
                              setDataState(e.dataState);
                            }}
                            expandField="expanded"
                            onExpandChange={expandChange}
                          >
                            <Column field="InstanceName" title="Instance" width={180} locked={true} cell={CellInstanceOverviewLabel} />
                            <Column field="ServiceDisplayName" title="Service Display Name" cell={RenderTooltipCell} locked={true} width={300} />
                            <Column field="DCName" title="Machine Name" width={150} cell={RenderTooltipCell}/>
                            <Column field="Platform" title="Platform" width={150} />
                            <Column field="LastPing" title="Last Ping" cell={CellDateTime} width={170} />
                            <Column field="Health" title="Health" cell={CellHealthStatus} width={120} />
                            {/*<Column field="ServiceId"  title={<CellColTitle title="NLog Alerts" />} cell={CellOpenAlertsCol} width={100}/>
                            <Column field="ServiceId"  title={<CellColTitle title="Tenanted Alerts" />} cell={CellTenantedAlertsCol} width={120}/>
                            <Column field="AlertTypes" title="Alert Types" cell={CellNumberedStringList} width={220} />
                            <Column field="RDPAccess" title="RDP Access" cell={CellAccessIcon} filterable={false} sortable={false} width={110} />
                            <Column field="ServiceId" title="ServiceId"  width={170} />*/}
                          </KendoGrid>
                        )
                        :
                        <Loader />
                    }
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>)}
    </WindowContext.Consumer>
  );
};

export { ServiceDetail }