import React, { useEffect, useState } from 'react'
import { Flex, Segment, List, Divider, Box, Text, Loader, Button } from '@fluentui/react-northstar'
import { Outlet, useNavigate } from "react-router-dom";
import { setRouteInLocalStorage } from '../../shared/contexts/Context';
import { ContactGroupIcon, SpeakerPersonIcon, SettingsIcon, CompanionIcon, ContentIcon, AttendeeIcon, AppFolderIcon, BellIcon, CallPstnIcon, PlugsIcon, TextBulletListTreeIcon, ListIcon, EmailIcon, OptionsIcon, SpotlightIcon, AudienceIcon, PersonIcon, MenuIcon, ShiftActivityIcon, ReactionsIcon, TvIcon } from "@fluentui/react-icons-northstar";

import './settings.scss';
import { SettingsVM } from './settingsVM';
import { SettingsServerAndVM } from './settingsServerAndVM';
import { SettingsPortals } from './settingsPortals';
import { InstanceServers } from './instanceServers';
import { SettingsUsers } from './settingsUsers';
import { SettingsVpnUsers } from './settingsVpnUsers';
import { SettingsMasterResellers } from './settingsMasterResellers';
import { SettingsDbRepoVersions, SettingsVersions } from './settingsVersions';
import { SettingsServiceAlert } from "./settingsServiceAlert";
import { SettingsServices } from "./settingsServices";
import { SettingsOnCallPlanner } from './settingsOnCall';
import { SettingsEmailLogs } from "./settingsEmailLogs";
import { SettingsAWSNotifications } from "./settingsAWSNotifications";
import { SettingsServerRules } from './settingsServerRules';
import { SettingsUserPermissions } from "./settingsUserPermissions";
import SettingsPriorityCustomers from './settingsPriorities';
import { OperationStatusIndicator } from '../../shared/appcomponents/enums';
import { Error403 } from '../../shared/error/403';
import { Error500 } from '../../shared/error/500';
import { SettingsBusinessHours } from "./settingsBusinessHours";
import { SettingsHoliday } from "./settingsHoliday";
import { SettingsLogs } from "./settingsLogs";
import { SettingsAPITokens } from "./settingsAPITokens";
import { SettingsEmailAnalysis } from "./settingsEmailAnalysis";
import { SettingsBrandsAnalysis } from "./settingsBrandsAnalysis";
import { SettingsCertificateStatus } from './settingsCertificateStatus';

const PermittedGroupsConfiguration = (props) => {
  if (props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite" ||
    p == "Settings.Configuration.Read" || p == "Settings.Configuration.ReadWrite")))
    return true;
  else return false;

}
const PermittedGroupsEmailDeliveryNotification = (props) => {
  if (props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite" ||
    p == "Settings.EmailDeliveryNotification.Read" || p == "Settings.EmailDeliveryNotification.ReadWrite")))
    return true;
  else return false;
}

const PermittedCert = (props) => {
  if (props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite")))
    return true;
  else return false;
}

const PermittedGroupsManageLogin = (props) => {
  if (props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite" ||
    p == "Settings.ManageLogin.Read" || p == "Settings.ManageLogin.ReadWrite")))
    return true;
  else return false;
}
const PermittedGroupsOnCall = (props) => {
  if (props.user?.Permissions?.some((p) => (p == "Settings.Read" || p == "Settings.ReadWrite" ||
    p == "Settings.OnCall.Read" || p == "Settings.OnCall.ReadWrite")))
    return true;
  else return false;
}

const PermittedCategories = (props) => {

  let items = [];
  if (PermittedGroupsConfiguration(props)) {
    items.push(...["Portals", "servers", "servers_VM", "InstanceServers", "Services", "ServiceAlert", "DBRepoVersions", "ServerRules", "PriorityCustomers", "MasterResellers", "BusinessHours", "Holiday", "SystemDiagnostics"]);
  }
  if (PermittedGroupsManageLogin(props)) {
    items.push(...["ManUsers", "UserPermissions"]);
  }
  if (PermittedGroupsOnCall(props)) {
    items.push("OnCallPlanner");
  }
  if (PermittedGroupsEmailDeliveryNotification(props)) {
    items.push(...["EmailLogs", "AWSNotifications"]);
  }
  if (PermittedCert(props)) {
    items.push(...["Certificate Status"]);
  }
  items.push(...["APITokens", "SentEmailAnalysis", "GetBrandsAnalysis"]);
  //items.push(...["Certificate Status"]);
  return items;
}


const ConfigCategories = (props) => {
  const [itemState, setItemState] = useState({});
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    let items = [],itemsCert=0, itemsConfig = 0, itemsManageLogin = 0, itemsOnCall = 0, itemsEmail = 0, itemsEmailSv = 0;
    if (PermittedGroupsConfiguration(props)) {
      items.push(...[{
        key: "Portals",
        header: "Instances",
        media: <ContentIcon />,
        name: "Instances",
      }, {
        key: "servers",
        header: "Servers",
        media: <CompanionIcon />,
        name: "Servers",
      }, {
        key: "InstanceServers",
        header: "Instance Servers Mapping",
        media: <PlugsIcon />,
        name: "InstanceServers",
      },
      {
        key: "servers_VM",
        header: "servers & VM",
        media: <PlugsIcon />,
        name: "servers_VM",
      }, {
        key: "Services",
        header: "Services",
        media: <SettingsIcon />,
        name: "Services",
      }, {
        key: "ServiceAlert",
        header: "Service Alerts",
        media: <BellIcon />,
        name: "ServiceAlert",
      }, {
        key: "DBRepoVersions",
        header: "DB Repo Versions",
        media: <TextBulletListTreeIcon />,
        name: "DBRepoVersions",
      }, {
        key: "ServerRules",
        header: "Server Rules",
        media: <OptionsIcon />,
        name: "ServerRules",
      }, {
        key: "PriorityCustomers",
        header: "Priority Customers",
        media: <SpotlightIcon />,
        name: "PriorityCustomers",
      }, {
        key: "MasterResellers",
        header: "Master Resellers",
        media: <PersonIcon />,
        name: "MasterResellers",
      }, {
        key: "BusinessHours",
        header: "Business Hours",
        media: <ShiftActivityIcon />,
        name: "BusinessHours",
      }, {
        key: "Holiday",
        header: "Holidays",
        media: <ReactionsIcon />,
        name: "Holiday",
      }, {
        key: "SystemDiagnostics",
        header: "System Diagnostics",
        media: <TvIcon />,
        name: "SystemDiagnostics",
      }]);
      itemsConfig = items.length;
    }
    if (PermittedGroupsManageLogin(props)) {
      items.push(...[{
        key: "ManUsers",
        header: "Manage Users",
        media: <ContactGroupIcon />,
        name: "ManageUsers",
      }, {
        key: "UserPermissions",
        header: "User Permission Groups",
        media: <AttendeeIcon />,
        name: "UserPermissions",
      }]);
      itemsManageLogin = items.length - itemsConfig;
    }
    if (PermittedGroupsOnCall(props)) {
      items.push(...[{
        key: "OnCallPlanner",
        header: "On-Call Planner",
        media: <CallPstnIcon />,
        name: "OnCallPlanner",
      }]);
      itemsOnCall = items.length - itemsConfig - itemsManageLogin;
    }
    if (PermittedGroupsEmailDeliveryNotification(props)) {
      items.push(...[{
        key: "EmailLogs",
        header: "Email Logs",
        media: <EmailIcon />,
        name: "EmailLogs",
      }, {
        key: "AWSNotifications",
        header: "AWS Notifications",
        media: <EmailIcon />,
        name: "AWSNotifications",
      }]);
      itemsEmail = items.length - itemsConfig - itemsManageLogin - itemsOnCall;
    }

    items.push(...[{
      key: "APITokens",
      header: "API Tokens",
      media: <EmailIcon />,
      name: "APITokens",
    }, {
      key: "SentEmailAnalysis",
      header: "Sent Email Analysis",
      media: <EmailIcon />,
      name: "SentEmailAnalysis",
    }, {
      key: "GetBrandsAnalysis",
      header: "Brands Analysis",
      media: <EmailIcon />,
      name: "GetBrandsAnalysis",
    }]);
    itemsEmailSv = 3;
    
    if (PermittedCert(props)) {
      items.push(...[{
        key: "CertificateStatus",
        header: "Certificate Status",
        media: <EmailIcon />,
        name: "CertificateStatus",
      }]);
      itemsCert = items.length - itemsConfig - itemsManageLogin - itemsOnCall - itemsEmail - itemsEmailSv;
    }

    setItemState({
      itemsConfig: itemsConfig,
      itemsManageLogin: itemsManageLogin,
      itemsOnCall: itemsOnCall,
      itemsEmail: itemsEmail,
      itemsEmailSv: itemsEmailSv,
      itemsCert: itemsCert
    })
    setListItems(items)

    debugger
  }, []);

  const onSelectChange = (index, newProps) => {
    debugger
    const category = newProps.items[newProps.selectedIndex].key;
    const header = newProps.items[newProps.selectedIndex].header;
    const name = newProps.items[newProps.selectedIndex].name;
    props.handleCategoryChange(index, category, name, header);
  };

  return (
    <div>
      {listItems.length > 0 && (
        <>
          {
            itemState?.itemsConfig > 0 ?
              <><Divider
                content={<Text content="Configuration" weight="bold" />}
              />
                <List
                  className="categorylist"
                  selectable
                  selectedIndex={props.selectedCategory}
                  onSelectedIndexChange={(e, newProps) => {
                    onSelectChange(newProps.selectedIndex, newProps);
                  }}
                  items={listItems.slice(0, itemState?.itemsConfig)}
                  style={{ width: "100%" }}
                /></>
              : <></>
          }
          {
            itemState?.itemsManageLogin > 0 ?
              <><Divider
                content={<Text content="App Access" weight="bold" />}
              />
                <List
                  className="categorylist"
                  selectable
                  selectedIndex={props.selectedCategory - itemState?.itemsConfig}
                  onSelectedIndexChange={(e, newProps) => {
                    onSelectChange(newProps.selectedIndex + itemState?.itemsConfig, newProps);
                  }}
                  items={listItems.slice(itemState?.itemsConfig, itemState?.itemsConfig + itemState?.itemsManageLogin)}
                  style={{ width: "100%" }}
                /></>
              : <></>
          }
          {
            itemState?.itemsOnCall > 0 ?
              <><Divider
                content={<Text content="On-Call" weight="bold" />}
              />
                <List
                  className="categorylist"
                  selectable
                  selectedIndex={props.selectedCategory - (itemState?.itemsConfig + itemState?.itemsManageLogin)}
                  onSelectedIndexChange={(e, newProps) => {
                    onSelectChange(newProps.selectedIndex + itemState?.itemsConfig + itemState?.itemsManageLogin, newProps);
                  }}
                  items={listItems.slice(itemState?.itemsConfig + itemState?.itemsManageLogin, itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall)}
                  style={{ width: "100%" }}
                /></>
              : <></>
          }
          {
            itemState?.itemsEmail > 0 ?
              <><Divider
                content={<Text content="Email Delivery" weight="bold" />}
              />
                <List
                  className="categorylist"
                  selectable
                  selectedIndex={props.selectedCategory - (itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall)}
                  onSelectedIndexChange={(e, newProps) => {
                    onSelectChange(newProps.selectedIndex + itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall, newProps);
                  }}
                  items={listItems.slice(itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall, itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail)}
                  style={{ width: "100%" }}
                /></>
              : <></>
          }
          {
            itemState?.itemsEmailSv > 0 ?
            <><Divider
              content={<Text content="Email Notification Service" weight="bold" />}
            />
              <List
                className="categorylist"
                selectable
                selectedIndex={props.selectedCategory - (itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail)}
                onSelectedIndexChange={(e, newProps) => {
                  onSelectChange(newProps.selectedIndex + itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail, newProps);
                }}
                items={listItems.slice(itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail , itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail + itemState?.itemsEmailSv )}
                style={{ width: "100%" }}
              /></>
              : <></>
          }
          {
            itemState?.itemsCert > 0 ?
              <><Divider
                content={<Text content="Certificate Status" weight="bold" />}
              />
                <List
                  className="categorylist"
                  selectable
                  selectedIndex={props.selectedCategory - (itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail  + itemState?.itemsEmailSv)}
                  onSelectedIndexChange={(e, newProps) => {
                    onSelectChange(newProps.selectedIndex + itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail + itemState?.itemsEmailSv, newProps);
                  }}
                  items={listItems.slice(itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail +  itemState?.itemsEmailSv, itemState?.itemsConfig + itemState?.itemsManageLogin + itemState?.itemsOnCall + itemState?.itemsEmail + itemState?.itemsEmailSv + itemState?.itemsCert)}
                  style={{ width: "100%" }}
                /></>
              : <></>
          }
        </>
      )}
    </div>
  );
};


const Settings = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryKey, setSelectedCategoryKey] = useState(null);
  let navigate = useNavigate();
  React.useEffect(() => {
    props.viewStatus.setPageStatus(null);
    props.viewStatus.InformChildPageStatus(null);
    let _permitted = PermittedCategories(props);
    if (_permitted.length > 0) {
      setSelectedCategory(0);
      setSelectedCategoryKey(_permitted[0]);
    }
    else {
      props.viewStatus.setPageStatus(401);
      // let path ='/dashboards/monitoring/infra/summary/'; 
      // navigate(path);
    }

    setLoaded(true);

  }, []);

  const handleCategoryChange = (_categoryIndex, _category, _name, _header) => {
    debugger
    props.viewStatus.InformChildPageStatus(null);
    setSelectedCategory(_categoryIndex);
    setSelectedCategoryKey(_category);
    let path = "/settings/" + _name;
    navigate(path);
    if (global.localStorage) {
      global.localStorage.setItem(
        'contentUrl_configuration',
        'configuration/' + _categoryIndex
      );
    }
  }

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  return loaded ?
    props.viewStatus.pageStatus == 401 ? <Error403 /> :
      props.viewStatus.pageStatus == 500 ? <Error500 /> :
        (
          <>
            <Box className="settings pt-3 pb-1">
              {selectedCategory !== null ? (
                <Flex className="addcardcontainer h-100" gap="gap.small"
                //padding="padding.medium"
                // style={{ minHeight: "100vh" }}
                >

                  <Flex.Item className="leftSideBar">
                    <Segment className={isActive ? "h-100 left-segment" : "active h-100 left-segment"}>
                      <Button icon={<MenuIcon />} iconOnly title="Close" onClick={ToggleClass} className="menu-icon" primary />
                      <ConfigCategories
                        selectedCategory={selectedCategory}
                        selectedCategoryKey={selectedCategoryKey}
                        handleCategoryChange={handleCategoryChange}
                        {...props}
                      />
                    </Segment>
                  </Flex.Item>
                  <Flex.Item className="rightSideBar h-100" size="size.half" grow>
                    <Segment className='p-0 h-100 right-segment'>
                      {
                        props.viewStatus.childpageStatus == 401 ? <Error403 /> :
                          props.viewStatus.childpageStatus == 500 ? <Error500 /> :
                            (
                              <>
                                <OperationStatusIndicator status={props.viewStatus.operationStatus} revertStatusToNull={() => props.viewStatus.DisplayOperationStatus(null)} />
                                {selectedCategoryKey === 'servers' && <SettingsVM {...props} />}
                                {selectedCategoryKey === 'servers_VM' && <SettingsServerAndVM {...props} />}
                                {selectedCategoryKey === 'Portals' && <SettingsPortals {...props} />}
                                {selectedCategoryKey === 'InstanceServers' && <InstanceServers {...props} />}
                                {selectedCategoryKey === 'PriorityCustomers' && <SettingsPriorityCustomers {...props} />}
                                {selectedCategoryKey === 'ManUsers' && <SettingsUsers {...props} />}
                                {selectedCategoryKey === 'UserPermissions' && <SettingsUserPermissions {...props} />}
                                {/* {selectedCategory === 4 && <SettingsVpnUsers {...props} />} */}
                                {selectedCategoryKey === 'MasterResellers' && <SettingsMasterResellers {...props} />}
                                {/* {selectedCategoryKey === 'Versions' && <SettingsVersions {...props} />} */}
                                {selectedCategoryKey === 'DBRepoVersions' && <SettingsDbRepoVersions {...props} />}
                                {selectedCategoryKey === 'ServiceAlert' && <SettingsServiceAlert {...props} />}
                                {selectedCategoryKey === 'Services' && <SettingsServices {...props} />}
                                {selectedCategoryKey === 'OnCallPlanner' && <SettingsOnCallPlanner {...props} />}
                                {selectedCategoryKey === 'EmailLogs' && <SettingsEmailLogs {...props} />}
                                {selectedCategoryKey === 'AWSNotifications' && <SettingsAWSNotifications {...props} />}
                                {selectedCategoryKey === 'ServerRules' && <SettingsServerRules {...props} />}
                                {selectedCategoryKey === 'BusinessHours' && <SettingsBusinessHours {...props} />}
                                {selectedCategoryKey === 'Holiday' && <SettingsHoliday {...props} />}
                                {selectedCategoryKey === 'SystemDiagnostics' && <SettingsLogs {...props} />}
                                {selectedCategoryKey === 'APITokens' && <SettingsAPITokens {...props} />}
                                {selectedCategoryKey === 'SentEmailAnalysis' && <SettingsEmailAnalysis {...props} />}
                                {selectedCategoryKey === 'GetBrandsAnalysis' && <SettingsBrandsAnalysis {...props} />}
                                {selectedCategoryKey === 'CertificateStatus' && <SettingsCertificateStatus {...props} />}
                              </>
                            )
                      }
                    </Segment>
                  </Flex.Item>
                </Flex>
              ) : (
                <Flex fill className="error" hAlign="center" vAlign="center"
                >
                  {/* <Segment className="auth-form-segment default-segment">
              <Flex gap="gap.small" column hAlign="center" vAlign="center">
                <Text
                  weight="semibold"
                  size="large"
                  content="Access Denied"
                />
                <Text content="Access to settings is restricted to App Owners or Team Owners." />
              </Flex>
            </Segment> */}

                  <div className="notfound">
                    <div className="notfound-section">
                      <h1>Oops!</h1>
                      <h2>
                        There's Nothing...!
                      </h2>
                    </div>
                    {/* <Link to="/" className="k-button k-primary py-3 px-5">Contact Administrator</Link> */}
                  </div>
                </Flex>
              )}
            </Box>
          </>
        ) : (
      <Loader />
    );
};

export { Settings };