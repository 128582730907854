import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Loader, Button, Flex, Tooltip,InfoIcon, Input, Card, CardHeader, Dropdown, CardBody, Pill } from '@fluentui/react-northstar';
import { ExcelColorIcon, ArrowUpIcon } from '@fluentui/react-icons-northstar';
import RevenueService from "../../../services/revenueservice";
import useMultiFilterDialog from './useMultiFilterDialog';
import '../microsoftbilling/microsoftbilling.scss';
import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';
import { RenderTooltipCell } from "../../../shared/appcomponents/TooltipWithCopy"

const PAGE_SIZE = 10;

const MicrosoftBillingDashboard = (props) => {
    const { acquireToken, viewStatus } = props;
    const [gridData, setGridData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [skip, setSkip] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('GBP');
    const [conversionRates, setConversionRates] = useState({});
    const [dataState, setDataState] = useState({
        sort: [{ field: "Product", dir: "asc" }],
        take: PAGE_SIZE,
        skip: 0,
    });

    const fetchGridData = useCallback((token, filters = {}) => {
        setLoaded(false);
        // Debugging output
        console.log('Fetching grid data with filters:', filters);

        const filterPayload = {
            ...filters,
        };

        if (filters.excludeInactive) {
            filterPayload.Active = true;
        } else {
            delete filterPayload.Active;
        }

        if (Object.keys(filterPayload).some(key => filterPayload[key] !== null)) {
            RevenueService.GetMicrosoftBillingGridDataFiltered(token, filterPayload)
                .then((response) => {
                    if (response && response.status === 200) {
                        setGridData(response.data);
                        setFilteredData(response.data.slice(skip, skip + PAGE_SIZE));
                        /* viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success); */
                    } else {
                        viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    }
                    setLoaded(true);
                })
                .catch((error) => {
                    console.error('Error fetching Microsoft Billing data:', error);
                    setLoaded(true);
                });
        } else {
            RevenueService.GetMicrosoftBillingGridData(token)
                .then((response) => {
                    if (response && response.status === 200) {
                        setGridData(response.data);
                        setFilteredData(response.data.slice(0, PAGE_SIZE));
                        setLoaded(true);
                        /* viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success); */
                    } else {
                        viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    }
                    setLoaded(true);
                })
                .catch((error) => {
                    console.error('Error fetching Microsoft Billing data:', error);
                    setLoaded(true);
                });
        }
    }, [viewStatus, skip]);


    const resetGridData = useCallback((token) => {
        RevenueService.GetMicrosoftBillingGridData(token) // Fetch data without filters
            .then((response) => {
                if (response && response.status === 200) {
                    setGridData(response.data);
                    setFilteredData(response.data.slice(skip, skip + PAGE_SIZE));
                    viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                } else {
                    viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                }
            })
            .catch((error) => {
                console.error('Error fetching grid data:', error);
                viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            });
    }, [skip]);

    useEffect(() => {
        acquireToken((token) => {
            fetchGridData(token, appliedFilters);
        });
    }, [acquireToken, appliedFilters, skip, fetchGridData]);

    useEffect(() => {
        setFilteredData(gridData.slice(skip, skip + PAGE_SIZE));
    }, [gridData, skip]);

    const handleFilterUpdate = (filters) => {
        const filterPayload = {
            Product: filters.Product.length > 0 ? filters.Product.join(',') : null,
            Instance: filters.Instance.length > 0 ? filters.Instance.join(',') : null,
            Platform: filters.Platform.length > 0 ? filters.Platform.join(',') : null,
            Region: filters.Region.length > 0 ? filters.Region.join(',') : null,
            StartDate: filters.startDate || null,
            EndDate: filters.endDate || null,
            excludeInactive: filters.excludeInactive
        };

        if (filters.excludeInactive) {
            filterPayload.Active = true;
        } else {
            delete filterPayload.Active;
        }
        setAppliedFilters(filterPayload);
        acquireToken((token) => {
            RevenueService.GetMicrosoftBillingGridDataFiltered(token, filterPayload)
                .then((response) => {
                    if (response.status === 200) {
                        setGridData(response.data);
                        setFilteredData(response.data.slice(0, PAGE_SIZE));
                        setSkip(0);
                        viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    } else {
                        console.error('Error fetching filtered data:', response.statusText);
                        viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    }
                })
                .catch((error) => {
                    console.error('Error in API call:', error);
                });
        });
    };

    const handleDownloadCsv = () => {
        console.log('Downloading with filters:', appliedFilters);
        setDownloading(true);
        acquireToken((token) => {
            RevenueService.GetMicrosoftBillingGridDataFiltered(token, appliedFilters)
                .then((response) => {
                    if (response.status === 200) {
                        // Prepare the CSV content
                        const csvContent = [];
                        const headers = [
                            "Product", "Platform", "Region", "Instance", "Subscription Id",
                            "Subscription Start Date", "Customer Name", "Customer Email", "License Description",
                            "Quantity", "Unit Price", "Currency", "Amount", "GBP Amount",
                            "Date Deactvated", "Active"
                        ];
                        csvContent.push(headers.join(","));

                        // Add rows with calculated "Amount GBP"
                        response.data.forEach(item => {
                            const amountInGBP = convertAmountToGBP(item.Amount, item.CurrencyCode);
                            const activeStatus = item.Active ? "Yes" : "No";
                            const row = [
                                item.Product, item.Platform, item.Region, item.Instance,
                                item.AMPSubscriptionId, item.SubscriptionStartDate||"N/A", item.Company,
                                item.PurchaserEmail, item.SubsName, item.AMPQuantity, item.Price,
                                item.CurrencyCode, item.Amount, amountInGBP.toFixed(2),
                                item.DateDeleted||"N/A", activeStatus
                            ];
                            csvContent.push(row.join(","));
                        });

                        // Convert data to blob and trigger download
                        const blob = new Blob([csvContent.join("\n")], { type: "text/csv" });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Marketplace_Customer_Billing.csv');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                    } else {
                        console.error('Error downloading CSV:', response.statusText);
                        viewStatus.DisplayOperationStatus(OPERATIONSTATUS.NoDownload);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setDownloading(false);
                });
        });
    };



    const handlePageChange = (event) => {
        const newSkip = event.page.skip;
        setSkip(newSkip);
        const newFilteredData = gridData.slice(newSkip, newSkip + PAGE_SIZE);
        setFilteredData(newFilteredData);
    };

    const filterDialog = useMultiFilterDialog({
        handleFilterUpdate,
        elegibleFilters: ['Product', 'Instance', 'Platform', 'Region', 'startDate', 'endDate', 'Active'], // Include "Date"
        acquireToken,
        resetGridData
    });

    /* const RenderTooltipCell = (props) => {
        const value = props.dataItem[props.field];
        return (
            <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip
                    content={value || 'N/A'} // Show "N/A" if value is null/undefined/empty
                    trigger={<span>{value || 'N/A'}</span>} // Display "N/A" in grid
                />
            </td>
        );
    }; */

    const RenderDefaultCell = (props) => {
        const value = props.dataItem[props.field];
        return (
            <td style={{ whiteSpace: 'nowrap' }}>
                {value || 'N/A'} {/* Show "N/A" if value is null/undefined/empty */}
            </td>
        );
    };


    /* const totalAmount = useMemo(() => {
        return gridData.reduce((sum, item) => sum + item.Amount, 0);
    }, [gridData]); */

    const totalAmount = useMemo(() => {
        return gridData
            .filter(item => item.Active === true)
            .reduce((sum, item) => sum + (Number(item.Amount)), 0)
            .toFixed(2);
    }, [gridData]);

    // Computation for additions should also consider the entire dataset
    const addedThisMonth = useMemo(() => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        return gridData.filter(item => {
            const itemDate = new Date(item.SubscriptionStartDate);
            return itemDate.getMonth() === currentMonth && itemDate.getFullYear() === currentYear;
        }).length;
    }, [gridData]);

    const addedLastMonth = useMemo(() => {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return gridData.filter(item => {
            const itemDate = new Date(item.SubscriptionStartDate);
            return itemDate.getMonth() === lastMonth.getMonth() && itemDate.getFullYear() === lastMonth.getFullYear();
        }).length;
    }, [gridData]);

    // Compute "Total Customers" (active customers)
    const totalCustomers = useMemo(() => {
        return gridData.filter(item => item.Active === true).length;
    }, [gridData]);

    const totalCustomersRemoved = useMemo(() => {
        return gridData.filter(item => item.Active === false).length;
    }, [gridData]);

    const removedThisMonth = useMemo(() => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        return gridData.filter(item => {
            const deletedDate = item.DateDeleted ? new Date(item.DateDeleted) : null;
            return (
                item.Active === false && // Active == false for removed
                deletedDate &&
                deletedDate.getMonth() === currentMonth &&
                deletedDate.getFullYear() === currentYear
            );
        }).length;
    }, [gridData]);

    const removedLastMonth = useMemo(() => {
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);

        return gridData.filter(item => {
            const deletedDate = item.DateDeleted ? new Date(item.DateDeleted) : null;
            return (
                item.Active === false && // Active == false for removed
                deletedDate &&
                deletedDate.getMonth() === lastMonth.getMonth() &&
                deletedDate.getFullYear() === lastMonth.getFullYear()
            );
        }).length;
    }, [gridData]);

    /* // Compute "Total Amount" (active customers only)
    const grossAmount = useMemo(() => {
        return gridData
            .filter(item => item.Active === true) // Only active customers
            .reduce((sum, item) => sum + (Number(item.Amount) || 0), 0)
            .toFixed(2);
    }, [gridData]); */

    /* const totalAmountInGBP = useMemo(() => {
        return gridData.reduce((sum, item) => {
            return sum + convertAmountToGBP(item.Amount, item.CurrencyCode);
        }, 0).toFixed(2);
    }, [gridData, conversionRates]);

    // Compute Removed Total Amount in GBP
    const totalRemovedAmountInGBP = useMemo(() => {
        return gridData.filter(item => item.Active === false)
            .reduce((sum, item) => {
                return sum + convertAmountToGBP(item.Amount, item.CurrencyCode);
            }, 0).toFixed(2);
    }, [gridData, conversionRates]); */

    const addedDetails = {
        thisMonth: {
            added: addedThisMonth,
            details: `Total added this month: ${addedThisMonth}`
        },
        lastMonth: {
            added: addedLastMonth,
            details: `Total added last month: ${addedLastMonth}`
        },
        totalAmountDetails: {
            amount: totalAmount.toLocaleString(),
            details: `Total amount for current filter: ${totalAmount.toLocaleString()}`
        }
    };
    const getPercentageChange = (value) => {
        const baseValue = totalCustomers || 1; // Protect against division by zero
        return ((value / baseValue) * 100).toFixed(1);
    };
    const handleSearch = (query) => {
        setSearchQuery(query.toLowerCase());
        const filtered = gridData.filter(item =>
            Object.keys(item).some(key =>
                String(item[key]).toLowerCase().includes(query.toLowerCase())
            )
        );
        setFilteredData(filtered.slice(skip, skip + PAGE_SIZE));
        setSkip(0); // Reset pagination after search
    };

    const RenderDateCell = (props) => {
        const value = props.dataItem[props.field];
        return (
            <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip
                    content={value || 'N/A'}
                    trigger={<span>{value || 'N/A'}</span>}
                />
            </td>
        );
    };

    function dynamicSort(property, order = 'asc') {
        let sortOrder = 1;
        if (order === 'desc') {
            sortOrder = -1;
        }
        return function (a, b) {
            // Handle null/undefined or special strings
            let valueA = a[property] === "N/A" || a[property] == null ? "" : a[property];
            let valueB = b[property] === "N/A" || b[property] == null ? "" : b[property];

            // Compare dates correctly
            if (property === "DateDeleted" && valueA !== "" && valueB !== "") {
                valueA = new Date(valueA);
                valueB = new Date(valueB);
            }

            if (property === "SubscriptionStartDate" && valueA !== "" && valueB !== "") {
                valueA = new Date(valueA);
                valueB = new Date(valueB);
            }

            // Numeric and string comparison
            if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
                valueA = Number(valueA);
                valueB = Number(valueB);
            } else {
                valueA = String(valueA).toLowerCase();
                valueB = String(valueB).toLowerCase();
            }

            return (valueA < valueB ? -1 : valueA > valueB ? 1 : 0) * sortOrder;
        };
    }

    // Example implementation for a header cell with sorting icons
    useEffect(() => {
        if (gridData.length > 0 && dataState.sort.length > 0) {
            const sortedData = [...gridData].sort(dynamicSort(dataState.sort[0].field, dataState.sort[0].dir));
            setFilteredData(sortedData.slice(skip, skip + PAGE_SIZE));
        }
    }, [gridData, dataState, skip]);

    const handleSortChange = (event) => {
        setDataState({ ...dataState, sort: event.sort });
    };

    const handleDataStateChange = (e) => {
        setDataState(e.data); // Update the data state with the new sorting configuration
    };

    const isEndDateToday = (endDate) => {
        if (!endDate) return false; // No end date provided
        const selectedEndDate = new Date(endDate);
        const currentDate = new Date();
        return (
            selectedEndDate.getFullYear() === currentDate.getFullYear() &&
            selectedEndDate.getMonth() === currentDate.getMonth() &&
            selectedEndDate.getDate() === currentDate.getDate()
        );
    };

    const fetchConversionRates = async () => {
        try {
            const response = await fetch('https://api.exchangerate-api.com/v4/latest/GBP');
            const data = await response.json();
            return data.rates; // returns the rates relative to GBP
        } catch (error) {
            console.error('Error fetching conversion rates:', error);
            return {}; // return empty object on error
        }
    };

    const convertAmountToGBP = (amount, currency) => {
        const rate = conversionRates[currency];
        return Number((amount / rate).toFixed(2));
    };

    useEffect(() => {
        fetchConversionRates().then(rates => setConversionRates(rates));
    }, []);

    const currencyOptions = [
        { key: 'GBP', value: 'GBP', text: 'GBP' },
        { key: 'USD', value: 'USD', text: 'USD' },
        { key: 'EUR', value: 'EUR', text: 'EUR' },
        { key: 'CHF', value: 'CHF', text: 'CHF' },
        { key: 'NZD', value: 'NZD', text: 'NZD' },
        { key: 'CAD', value: 'CAD', text: 'CAD' },
        { key: 'AUD', value: 'AUD', text: 'AUD' },
    ];

    const convertToSelectedCurrency = (amount, currency) => {
        const conversionRate = conversionRates[currency];
        return (amount * conversionRate).toFixed(2);
    };

    const totalAmountActive = useMemo(() => {
        return gridData
            .filter(item => item.Active) // Only active customers
            .reduce((sum, item) => sum + parseFloat(convertAmountToGBP(item.Amount, item.CurrencyCode)), 0)
            .toFixed(2);
    }, [gridData, conversionRates]); // Include conversionRates as a dependency if used

    const totalAmountRemoved = useMemo(() => {
        return gridData
            .filter(item => !item.Active) // Only removed customers
            .reduce((sum, item) => sum + parseFloat(convertAmountToGBP(item.Amount, item.CurrencyCode)), 0)
            .toFixed(2);
    }, [gridData, conversionRates]); // Include conversionRates as a dependency if used

    /* const totalAmountRemoved = useMemo(() => {
        return gridData
            .filter(item => item.Active === false) // Only removed customers
            .reduce((sum, item) => sum + (Number(item.Amount)), 0)
            .toFixed(2);
    }, [gridData]); */

    console.log("Active amount:", totalAmountActive);
    console.log("Removed amount:", totalAmountRemoved);

    const totalInSelectedCurrencyRemoved = convertToSelectedCurrency(totalAmountRemoved, selectedCurrency);

    const currencyRemovedTotalFormatted = `${totalInSelectedCurrencyRemoved} ${selectedCurrency}`;

    console.log(currencyOptions);

    const totalInSelectedCurrency = convertToSelectedCurrency(totalAmount, selectedCurrency);

    const currencyAddedTotalFormatted = `${totalInSelectedCurrency} ${selectedCurrency}`;



    function formatDate(dateString) {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: '2-digit' };
        return date.toLocaleDateString(undefined, options);
    }
    return (
        <div className="microsoft-billing-dashboard">

            <Flex space="between" className="filter-pill-container">
                {filterDialog}
                <Flex>
                    <div>
                        <Flex>
                            {/* <Dropdown
                                items={currencyOptions}
                                value={selectedCurrency}
                                onChange={(event, data) => setSelectedCurrency(data.value)}
                            /> */}
                            <Input
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            <div className='icnTrk ml-2 download-icon'>
                                {downloading ? (
                                    <Loader size="smallest" />
                                ) : (
                                    <Button
                                        title="Download Excel"
                                        icon={<ExcelColorIcon outline rotate={0} size="large" />}
                                        iconOnly
                                        onClick={handleDownloadCsv}
                                    />
                                )}
                            </div>
                        </Flex>
                    </div>
                </Flex>
            </Flex>
            <div className='row d-flex align-items-stretch top-kpi'>
                {(Object.keys(appliedFilters).length === 0 ||
                    isEndDateToday(appliedFilters.EndDate)) && (
                        <>
                            <div className='col-12 col-md-3 mb-4'>
                                <Card className='card-border bg-light-purple rev-chart'>
                                    <CardHeader>
                                        <h6>Total Active Customers</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <h1>{totalCustomers}</h1>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-12 col-md-3 mb-4'>
                                <Card className='card-border bg-light-purple rev-chart'>
                                    <CardHeader>
                                        <h6>Total Amount (Active Customers)</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <h1>£{totalAmountActive}</h1>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-12 col-md-3 mb-4'>
                                <Card className='card-border bg-light-purple rev-chart'>
                                    <CardHeader>
                                        <h6>Added This Month</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <h1>{addedDetails.thisMonth.added}</h1>
                                        <Pill className='healthy'>+{getPercentageChange(addedDetails.thisMonth.added)}%<ArrowUpIcon /></Pill>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-12 col-md-3 mb-4'>
                                <Card className='card-border bg-light-purple rev-chart'>
                                    <CardHeader>
                                        <h6>Added Last Month</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <h1>{addedDetails.lastMonth.added}</h1>
                                        <Pill className='healthy'>+{getPercentageChange(addedDetails.lastMonth.added)}%<ArrowUpIcon /></Pill>
                                    </CardBody>
                                </Card>
                            </div>
                            {/* Only Render Removed Customers section if excludeInactive is off */}
                            {!appliedFilters.excludeInactive && (
                                <>
                                    <div className="col-12 col-md-3 mb-4">
                                        <Card className="card-border bg-light-purple rev-chart">
                                            <CardHeader>
                                                <h6>Total Customers Removed</h6>
                                            </CardHeader>
                                            <CardBody>
                                                <h1>{totalCustomersRemoved}</h1>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-12 col-md-3 mb-4'>
                                        <Card className='card-border bg-light-purple rev-chart'>
                                            <CardHeader>
                                                <h6>Total Amount (Removed Customers)</h6>
                                            </CardHeader>
                                            <CardBody>
                                                <h1>£{totalAmountRemoved}</h1>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-12 col-md-3 mb-4">
                                        <Card className="card-border bg-light-purple rev-chart">
                                            <CardHeader>
                                                <h6>Removed This Month</h6>
                                            </CardHeader>
                                            <CardBody>
                                                <h1>{removedThisMonth}</h1>
                                                <Pill className="critical">
                                                    -{getPercentageChange(removedThisMonth)}%
                                                    <ArrowUpIcon rotate="180" />
                                                </Pill>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-12 col-md-3 mb-4">
                                        <Card className="card-border bg-light-purple rev-chart">
                                            <CardHeader>
                                                <h6>Removed Last Month</h6>
                                            </CardHeader>
                                            <CardBody>
                                                <h1>{removedLastMonth}</h1>
                                                <Pill className="critical">
                                                    -{getPercentageChange(removedLastMonth)}%
                                                    <ArrowUpIcon rotate="180" />
                                                </Pill>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </>
                            )}
                        </>
                    )}
            </div>
            <div style={{ width: '100%', overflowX: 'auto', paddingBottom: '1rem' }}>
                <Grid
                    data={filteredData}
                    skip={skip}
                    pageable={true}
                    pageSize={PAGE_SIZE}
                    total={gridData.length}
                    onPageChange={handlePageChange}
                    resizable
                    onSortChange={handleSortChange}
                    onDataStateChange={handleDataStateChange}
                    sortable={true}
                    sort={dataState.sort}
                >
                    <Column
                        field="Product"
                        title="Product"
                        width="150px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        field="Platform"
                        title="Platform"
                        width="150px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        field="Region"
                        title="Region"
                        width="80px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        field="Instance"
                        title="Instance"
                        width="120px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        field="AMPSubscriptionId"
                        title="Subscription ID"
                        width="140px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="SubscriptionStartDate"
                        title="Subs Start Date"
                        width="130px"
                        cell={RenderDateCell}
                    />
                    <Column
                        field="Company"
                        title="Customer Name"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="PurchaserEmail"
                        title="Customer Email"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="SubsName"
                        title="License Description"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="AMPQuantity"
                        title="Quantity"
                        width="80px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        field="Price"
                        title="Unit Price"
                        width="90px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        field="Amount"
                        title="Amount"
                        width="80px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        field="CurrencyCode"
                        title="Currency"
                        width="90px"
                        cell={RenderDefaultCell}
                    />
                    <Column
                        title="GBP Amount"
                        field="AmountGBP"
                        width="110px"
                        cell={props => {
                            const amountInGBP = convertAmountToGBP(props.dataItem.Amount, props.dataItem.CurrencyCode);
                            return (
                                <td>£{amountInGBP.toFixed(2)}</td>
                            );
                        }}
                    />
                    {/* <Column
                        field="TermUnit"
                        title="Term Unit"
                        width="90px"
                        cell={RenderDefaultCell}
                    /> */}
                    {!appliedFilters.excludeInactive && (
                        <Column
                        field="DateDeleted"
                        title={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                Date Deactivated
                                <Tooltip
                                    pointing
                                    trigger={<InfoIcon style={{ marginLeft: '5px', cursor: 'pointer' }} />} // Adjust color
                                    content="Date on which subscription was cancelled"
                                />
                            </span>
                        }
                        sortable={true}
                        width="170px"
                        cell={(props) => (
                            <td>{formatDate(props.dataItem[props.field])}</td>
                        )}
                    />
                    )}
                    <Column
                        field="Active"
                        title="Active"
                        width="80px"
                        cell={(props) => (
                            <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field] ? "Yes" : "No"}</td>
                        )}
                    />
                </Grid>
            </div>
            {!loaded && (
                <div className="loading-indicator">
                    <Loader type="infinite-spinner" />
                    Loading data...
                </div>
            )}
        </div>
    );
};

export default MicrosoftBillingDashboard;
